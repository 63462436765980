import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import setLanguageText from "../../language/setLanguageText";
import SingleProductPDF from "../../pdf/SingleProductPDF";
import { useMutation } from "react-query";
import { UserContext } from "../../store/UserContext";
import axiosRequest from "../../axios/axoisRequest";
import config from "../../config";
import SpinningLoader from "../../reusable/SpinnningLoader";
import { ToggleButton } from "react-bootstrap";

const createImageUrl = (data) => {
  let base64String = "data:image/png;base64," + data;
  var base64DataCleaned = base64String.replace(
    /^data:image\/(png|jpeg|jpg);base64,/,
    ""
  );
  const byteCharacters = window.atob(base64DataCleaned);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "image/png" });
  var objectURL = URL.createObjectURL(blob);
  return objectURL;
};

const ProductPDFDialog = ({ product, open, setOpen }) => {
  const { user } = useContext(UserContext);
  const [downloadObject, setDownloadObject] = useState(false);
  const [techImage, setTechImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [brandImage, setBrandImage] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [price, setPrice] = useState(false);

  const downloadButton = () => {
    var blob = new Blob([downloadObject.blob], {
      type: "application/pdf;charset=utf-8",
    });
    saveAs(
      blob,
      `${
        config.APPNAME === "cis"
          ? "CIS-"
          : config.APPNAME === "act"
          ? "ACT-"
          : "DEV-"
      }${product.product_id}.pdf`
    );
  };

  useEffect(() => {
    //console.log(product)
    if (product?.product_brand_url) {
      get_brand_image.mutate(product.product_brand_url);
    }
    if (product?.product_image) {
      if (product?.product_image && product.product_dataset === "cim") {
        product?.product_image.forEach((image) => {
          if (image?.type === "CategoryTechincalDiagram") {
            setTechImage(image.url);
          } else if (image?.type === "Photo") {
            setMainImage(image.url);
          } else {
            setOtherImages((current) => [...current, image]);
          }
        });
        setLoaded(true);
      } else if (product.product_dataset === "ezbase") {
        //get main image
        if (product?.product_image[0]?.url) {
          get_main_image.mutate(product.product_image[0].url);
        } else {
          //no image
          setLoaded(true);
        }
      }
    } else {
      setLoaded(true);
    }
  }, [product]);

  const get_main_image = useMutation(
    (url) => {
      return axiosRequest({
        method: "get",
        endpoint: "image_proxy",
        gateway: config.factoryAPI.URL,
        api_key: user.api_key,
        params: {
          url: encodeURI(url),
        },
      });
    },
    {
      onSuccess: (data) => {
        //console.log(data)
        let mainImage = createImageUrl(data.body);
        setMainImage(mainImage);
        setLoaded(true);
      },
    }
  );

  const get_brand_image = useMutation(
    (url) => {
      return axiosRequest({
        method: "get",
        endpoint: "image_proxy",
        gateway: config.factoryAPI.URL,
        api_key: user.api_key,
        params: {
          url: encodeURI(url),
        },
      });
    },
    {
      onSuccess: (data) => {
        //console.log(data)
        let brandImage = createImageUrl(data.body);
        setBrandImage(brandImage);
      },
    }
  );

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={closeModal}
      fullScreen
      sx={{ margin: "5%" }}
    >
      <DialogContent
        sx={{ position: "relative", padding: "0px", overflow: "hidden" }}
      >
        {loaded ? (
          <SingleProductPDF
            orientation="portrait"
            product={product}
            setDownloadObject={setDownloadObject}
            brandImage={brandImage}
            mainImage={mainImage}
            techImage={techImage}
            otherImages={otherImages}
            price={price}
          />
        ) : (
          <Box sx={{ display: "grid", height: "100%" }}>
            <SpinningLoader text="Loading Images..." />
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        {/* <Grid item> */}
        <Grid container spacing={1} justifyContent="flex-end">
          {user?.user?.user_permissions?.administrator && (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={price}
                    onChange={() => setPrice((price) => !price)}
                    name="price"
                  />
                }
                label="Show Price"
              />
            </Grid>
          )}
          {downloadObject && (
            <Grid item>
              <Button
                onClick={(e) => downloadButton()}
                color="primary"
                variant="contained"
              >
                <FontAwesomeIcon
                  style={{ marginRight: "10px", textDecoration: "none" }}
                  icon={faDownload}
                />
                {setLanguageText("Download PDF")}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button onClick={closeModal} variant="contained">
              {setLanguageText("Close")}
            </Button>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </DialogActions>
    </Dialog>
  );
};

const ExportSingleProduct = ({ product, erpDetails }) => {
  const [productDetails, setProductDetails] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (product) {
      setProductDetails({
        ...product,
        ...erpDetails,
      });
    }
  }, [product, erpDetails]);
  //console.log(base64Image)

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disabled={!productDetails}
        onClick={() => {
          setOpen(true);
        }}
        endIcon={<FontAwesomeIcon icon={faDownload} />}
      >
        {setLanguageText("Export")}
      </Button>
      {open ? (
        <ProductPDFDialog
          product={productDetails}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </>
  );
};

export default ExportSingleProduct;
