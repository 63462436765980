const createProductObject = async (product) => {
  let object = product.map((product) => {
    if (product.data === "cim") {
      let has3D = false;

      if (product?.Graphics) {
        product?.Graphics.some((image) => {
          if (
            image.Type === "Detail3DModel" ||
            image.Type === "Generated3DModel"
          ) {
            has3D = true;
          }
        });
      }

      return {
        product_cim_id: product?.ID,
        product_cim_number: product?.ArticleNumber,
        product_name: product?.Name,
        product_company: product?.CompanyCode,
        product_category: product?.Category,
        product_image: product?.Graphics
          ? product.Graphics.map((image) => {
              return {
                url: image?.URL,
                type: image?.Type,
                viewer: image?.Viewer,
              };
            })
          : [],
        product_attributes: product?.Properties
          ? product.Properties.map((property, i) => {
              return {
                id: i,
                code: property?.DIN,
                name: property?.Label,
                value: property?.Value,
                unit: property?.Unit,
              };
            })
          : [],
        product_matching: product?.InterfaceCodes
          ? product.InterfaceCodes.map((item) => {
              return item;
            })
          : [],
        product_dataset: "cim",
        product_area: "opensearch",
        product_3d: has3D,
      };
    } else if (product.data === "ezbase") {
      //ezbase

      let normalised = [];
      if (product?.NormalizedProperties) {
        //check if it is an object or a string
        if (typeof product?.NormalizedProperties === "string") {
          product.NormalizedProperties = JSON.parse(
            product.NormalizedProperties
          );
        }
        //console.log(product.NormalizedProperties)
        if (product?.NormalizedProperties.length) {
          normalised = product.NormalizedProperties.map((header) => {
            return {
              id: header?.id,
              name: header?.header,
              value: header?.value,
              unit: header?.unit,
            };
          });
        }
      }
      let attributes = [];
      if (product?.ArticleTableHeader) {
        //check if it is an object or a string
        if (typeof product?.ArticleTableHeader === "string") {
          product.ArticleTableHeader = JSON.parse(product.ArticleTableHeader);
        }
        if (product?.ArticleTableHeader.length) {
          attributes = product.ArticleTableHeader.map((header) => {
            //find the value from the TableProperties
            let value = product.TableProperties.find(
              (property) => property.id === header.id
            );
            return {
              id: header?.id,
              name: header?.header,
              value: value?.value,
            };
          });
        }
      }
      let allImages = [];
      let downloads = [];

      if (product?.Assets) {
        //check if it is an object or a string
        if (typeof product?.Assets === "string") {
          product.Assets = JSON.parse(product.Assets);
        }
        if (product?.Assets.length) {
          product.Assets.map((image) => {
            if (image.type === "Image") {
              allImages.push(image);
            } else if (image.type === "Url") {
              downloads.push(image);
            }
          });
        }
        //only has main image
      } else if (product?.MainImage) {
        allImages = [{ url: product?.MainImage, type: "main image" }];
      }

      return {
        product_id_ezb: product?.ArticleID,
        product_number_ezb: product?.ArticleNumber,
        product_description_ezb: product?.Description,
        product_group: product?.ArticleGroupID,
        product_ean: product?.ArticleGtin,
        product_name: product?.ArticleName,
        product_company: product?.SupplierName,
        product_html: product?.SpecificationHeader,
        product_image: allImages ? allImages : [],
        product_area: "opensearch",
        product_variants: product?.ProductCount,
        product_attributes: normalised ? normalised : [],
        //"product_attributes": attributes ? attributes : [],
        product_downloads: downloads ? downloads : [],
        product_dataset: "ezbase",
        product_brand: product?.Brand ? product.Brand : "",
        //"product_brand": brand ? { 'url': brand.url, "name": brand.description } : "",
      };
    }
  });
  //console.log(object)

  return object[0];
};
export default createProductObject;
