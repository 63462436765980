import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import BasketItem from "../basket/BasketItem";
import BasketTotals from "../basket/BasketTotals";
import { BasketContext } from "../store/BasketContext";
import Delivery from "./Delivery";
import Payment from "./Payment";
import Shipping from "./Shipping";
import { UserContext } from "../store/UserContext";
import { CompanyContext } from "../store/CompanyContext";
import { useMutation } from "react-query";
import axiosRequest from "../axios/axoisRequest";
import Loader from "../reusable/Loader";
import config from "../config";
import { UPDATE_BASKET } from "../store/BasketReducers";
import OrderEmail from "../emails/OrderEmail";
import { LayoutContext } from "../store/LayoutContext";
import { renderToString } from "react-dom/server";
import GlobalDialog from "../reusable/GlobalDialog";
import OrderConfirmationEmail from "../emails/OrderConfirmationEmail";
import SaveQuote from "./SaveQuote";
import { faCartShopping } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateBasketTotals } from "../basket/helpers/updateBasketTotals";
import { SELECTED_COMPANY } from "../store/CompanyReducers";
import { trackEvent } from "../google_analytics/ga";

const Checkout = ({ setOpenCheckout, closeBasket }) => {
  const { basket, dispatchBasket } = useContext(BasketContext);
  const { app, theme: layoutTheme } = useContext(LayoutContext);
  const { user } = useContext(UserContext);
  const { selectedCompany, dispatchSelectedCompany } =
    useContext(CompanyContext);
  const [orderNumber, setOrderNumber] = useState("");
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const send_email = useMutation((order) => {
    //render the email
    const emailString = renderToString(
      <OrderEmail order={order} logo={layoutTheme.email_logo} theme={theme} />
    );

    let emails = [];

    //if cis then only add application email if no company email
    if (config.APPNAME === "cis") {
      if (
        app.emails.order_email &&
        !selectedCompany?.company_settings?.order?.email
      ) {
        //application order email
        emails.push(app.emails.order_email);
      } else if (selectedCompany?.company_settings?.order?.email) {
        //company order emails
        selectedCompany.company_settings.order.email.forEach((email) => {
          emails.push(email);
        });
      }
    } else {
      if (app.emails.order_email) {
        //application order email
        emails.push(app.emails.order_email);
      }
      if (selectedCompany?.company_settings?.order?.email) {
        //company order emails
        selectedCompany.company_settings.order.email.forEach((email) => {
          emails.push(email);
        });
      }
    }

    //console.log(order);
    return axiosRequest({
      gateway: config.factoryAPI.URL,
      endpoint: "email",
      api_key: user.api_key,
      method: "post",
      body: {
        email: emails,
        html: emailString,
        subject: "New Order Placed",
      },
    });
  });

  //gets companies full details
  const refresh_company = useMutation(
    (company_id) => {
      //console.log(user)
      return axiosRequest({
        method: "get",
        endpoint: "companies",
        gateway: config.coolantAPI.URL,
        api_key: user.api_key,
        params: {
          id: company_id,
        },
      });
    },
    {
      onSuccess: (data) => {
        dispatchSelectedCompany({
          type: SELECTED_COMPANY,
          payload: data[0],
        });
        window.sessionStorage.setItem("selectedCompany", data[0].company_id);
      },
    }
  );

  const send_confirmation_email = useMutation((order) => {
    //render the email
    const emailString = renderToString(
      <OrderConfirmationEmail
        order={order}
        logo={layoutTheme.email_logo}
        theme={theme}
      />
    );

    //console.log(order);
    return axiosRequest({
      gateway: config.factoryAPI.URL,
      endpoint: "email",
      api_key: user.api_key,
      method: "post",
      body: {
        email: [user.user.email],
        html: emailString,
        subject: "New Order Placed",
      },
    });
  });

  const add_credits_total = useMutation(
    (credits) => {
      return axiosRequest({
        endpoint: "company/settings",
        gateway: config.coolantAPI.URL,
        api_key: user.api_key,
        method: "patch",
        body: {
          credits: credits,
          credit_type: "add",
          company_id: selectedCompany.company_id,
        },
      });
    },
    {
      onSuccess: (data, variables, context) => {
        refresh_company.mutate(selectedCompany.company_id);
      },
    }
  );

  const add_credits = useMutation(
    (body) => {
      return axiosRequest({
        gateway: config.shopAPI.URL,
        endpoint: "credits",
        api_key: user.api_key,
        method: "post",
        body: body,
      });
    },
    {
      onSuccess: async (data, variables, context) => {
        //console.log(data);
        if (variables.credit_approved) {
          add_credits_total.mutate(variables.credit_amount);
        }
      },
    }
  );

  //update basket
  const update_basket = useMutation((basket) => {
    return axiosRequest({
      gateway: config.shopAPI.URL,
      endpoint: "basket",
      api_key: user.api_key,
      method: "patch",
      body: {
        basket_id: basket.basket_id,
        basket_name: basket.basket_name,
        basket_items: [],
      },
    });
  });

  //save order
  const save_order = useMutation(
    (full_order) => {
      return axiosRequest({
        gateway: config.factoryAPI.URL,
        endpoint: "orders",
        api_key: user.api_key,
        method: "post",
        body: full_order,
      });
    },
    {
      onSuccess: async (data, variables, context) => {
        //clear basket and close checkout

        //send emails
        send_email.mutate(variables);
        send_confirmation_email.mutate(variables);

        //add credits

        let tokens = 0;
        variables.order_items.forEach((item) => {
          if (item.product_area === "tokens") {
            tokens = tokens + item.product_pack_size;
          }
        });
        //let tokens = variables.order_items.find((item) => item.product_id === -1 && item.product_area === "tokens")
        if (tokens) {
          add_credits.mutate({
            user_id: variables.order_user,
            company_id: variables.order_company.company_id,
            credit_amount: tokens,
            credit_approved: true,
            approved_date: new Date(),
          });
        }

        //update basket
        update_basket.mutate(basket);
        dispatchBasket({
          type: UPDATE_BASKET,
          payload: {
            items: [],
          },
        });
        setOrderNumber("");
        updateBasketTotals([], dispatchBasket);
      },
    }
  );

  const handleSend = async (basket, orderNumber) => {
    //loop through basket items and create object for order
    //let updateObject = await createProductObject(basket.items)

    //console.log(basket)
    let items = basket.items.map((item) => {
      return {
        item_id: item.product_id,
        item_name: item.product_description,
        product_number: item.product_number,
        product_supplier: item.product_supplier,
      };
    });
    //add to google analytics
    trackEvent({
      action: "purchase",
      params: {
        currency: "GBP",
        value: basket.totals,
        items: items,
      },
    });

    const full_order = {
      order_user: {
        user_name: user.user.username,
        user_id: user.user.user_id,
        user_email: user.user.email,
      },
      order_company: {
        company_id: selectedCompany.company_id,
        company_name: selectedCompany.company_name,
      },
      order_items: basket.items,
      order_stage: "new_order",
      order_total: basket.totals,
      order_date: new Date(),
      order_number: orderNumber,
    };
    //save order
    save_order.mutate(full_order);
  };

  return (
    <Grid
      container
      sx={{
        height: "100%",
        overflow: "auto",
        background: (theme) => theme.palette.background.default,
      }}
    >
      {useMediaQuery(theme.breakpoints.down("md")) && (
        <Grid
          container
          sx={{
            padding: "1em",
            background: (theme) => theme.palette.cards.header,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ color: "text.primary" }}>
              Checkout
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={(e) => setOpenCheckout(false)}
              sx={{ float: "right" }}
            >
              <Typography variant="body2">Close Checkout</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        container
        alignContent="start"
        xs={12}
        md={6}
        lg={8}
        sx={{
          padding: "1em",
          background: (theme) => theme.palette.cards.header,
        }}
        order={{ xs: 2, md: 1 }}
      >
        {useMediaQuery(theme.breakpoints.up("md")) && (
          <Grid
            container
            sx={{
              padding: " 0 0 1em 0 ",
              marginBottom: "1em",
              background: (theme) => theme.palette.cards.header,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ color: "text.primary" }}>
                Checkout
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={(e) => setOpenCheckout(false)}
                sx={{ float: "right" }}
              >
                <Typography variant="body2">Close Checkout</Typography>
              </Button>
            </Grid>
          </Grid>
        )}

        {selectedCompany?.company_erp ? (
          <Grid item container spacing={2}>
            <Delivery />
            {/* <Shipping /> */}
            <Payment />
            <Grid
              item
              xs={12}
              sx={{
                padding: "1em",
              }}
            >
              <BasketTotals />
            </Grid>
            {!orderNumber && user?.user?.user_settings?.can_checkout ? (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                justifyContent="end"
                alignItems="end"
              >
                <Grid item>
                  <Alert severity="info" sx={{ alignItems: "center" }}>
                    Please add a purchase order number to complete this order.
                  </Alert>
                </Grid>
              </Grid>
            ) : null}
            {user?.user?.user_settings?.can_checkout ? null : (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                justifyContent="end"
                alignItems="end"
              >
                <Grid item>
                  <Alert severity="error">
                    Apologies, you don&apos;t currently have permission to
                    checkout. Please save this quote below and send the to
                    someone who has permission. If you are supposed to have
                    permission, please contact your company&apos;s Superuser.
                  </Alert>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              spacing={2}
              justifyContent="end"
              alignItems="end"
            >
              <Grid item>
                <TextField
                  disabled={
                    user?.user?.user_settings?.can_checkout ? false : true
                  }
                  sx={{ "& legend": { display: "none" }, width: "300px" }}
                  id="order-numer"
                  //label="Purchase Order Number"
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                  placeholder="Purchase Order Number"
                />
              </Grid>

              <Grid item sx={{ height: "100%" }}>
                <Button
                  variant="contained"
                  onClick={(e) => handleSend(basket, orderNumber)}
                  sx={{ height: "100%" }}
                  //color="success"
                  size="large"
                  disabled={
                    user?.user?.user_settings?.can_checkout && orderNumber
                      ? false
                      : true
                  }
                  startIcon={<FontAwesomeIcon icon={faCartShopping} />}
                >
                  Complete Order
                </Button>
              </Grid>
              {save_order.isLoading ||
              save_order.isSuccess ||
              save_order.isError ? (
                <Loader
                  isLoading={save_order.isLoading}
                  mutation={save_order}
                  loadingMessage="Processing Order..."
                  successMessage="Order sent successfully"
                  callback={() => {
                    setOpen(true);
                    //navigate('/solution/dashboard')
                    //console.log('uploading files')
                  }}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} justifyContent="end" alignItems="end">
              <Grid item>
                <Typography variant="caption" component="p" textAlign="right">
                  Need approval? Save this quote then email an approver to get a
                  purchase order number.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="end" alignItems="end">
              <Grid item>
                <SaveQuote
                  basket={basket}
                  setOpenCheckout={setOpenCheckout}
                  closeBasket={closeBasket}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ padding: "1em!important" }}>
                <Alert severity="error">
                  No customer account link to your user, please contact support
                  to allow purchases on your account.
                </Alert>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      {useMediaQuery(theme.breakpoints.down("md")) && (
        <Grid item xs={12} sx={{ padding: "1em" }}>
          <Typography variant="h6">Basket Items</Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{
          height: "300px",
          overflow: "auto",
          background: theme.palette.background.paper,
          [theme.breakpoints.up("md")]: {
            height: "100%",
          },
          [theme.breakpoints.down("md")]: {
            margin: "0 1em",
            borderRadius: "4px",
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          },
          //background: '#f6f6f6',
          borderLeft: "1px solid #e2e2e2",
        }}
        order={{ xs: 1, md: 2 }}
      >
        <Box
          sx={{
            background: theme.palette.background.default,
            borderBottom: "1px solid #ccc",
            padding: "20px",
          }}
        >
          <Typography color="text.secondary" sx={{ marginLeft: "1em" }}>
            Basket: <strong>{basket.basket_name}</strong>
          </Typography>
        </Box>
        <Box sx={{ padding: "1em" }}>
          {basket.items.length > 0 ? (
            basket.items.map((product, i) => (
              <BasketItem key={i} product={product} setOpen={closeBasket} />
            ))
          ) : (
            <p className="no_items">
              <i className="fas fa-exclamation-circle"></i>
              Your basket is empty.
            </p>
          )}
        </Box>
      </Grid>
      <GlobalDialog
        open={open}
        onClose={() => {
          setOpenCheckout(false);
          closeBasket(false);
          setOpen(false);
          setOrderNumber("");
          save_order.reset();
        }}
        title="Order Complete"
        maxWidth="md"
        closeText="Continue Shopping"
      >
        <Typography
          textAlign="center"
          variant="h4"
          component="p"
          sx={{
            color: (theme) => theme.palette.primary.main,
            marginBottom: "12px",
          }}
        >
          Order Complete!
        </Typography>
        <Typography textAlign="center" variant="p" component="p">
          Thank you for your order.
        </Typography>
        <Typography textAlign="center" variant="p" component="p">
          We are currently processing and we will be in touch soon.
        </Typography>
        <Typography textAlign="center" variant="p" component="p">
          Please check you emails including your junk folder for confirmation.
        </Typography>
      </GlobalDialog>
    </Grid>
  );
};

export default Checkout;
