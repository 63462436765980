import { useContext, useEffect, useState } from "react";
import config from "../config";
import axiosRequest from "../axios/axoisRequest";
import { UserContext } from "../store/UserContext";
import { CategoryContext } from "../store/CategoryContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import createProductObject from "../products/opensearch/createProductObject";

export default function useEnhancedCIM({ setSelectedProduct, product }) {
  const { categories } = useContext(CategoryContext);
  const { user } = useContext(UserContext);

  const { data: cim_data, isFetching: cim_isFecthing } = useQuery({
    queryKey: [
      "enhance cim",
      product?.product_number,
      product?.product_cim_code,
      product?.product_supplier_number,
    ],
    queryFn: ({ signal }) => {
      if (!product?.product_cim_id && product?.product_cim_code) {
        //console.log(product);
        let params = {
          product_id: product?.product_id,
          product_code: product?.product_number,
          product_cim_code: product?.product_cim_code,
          product_supplier_number: product?.product_supplier_number,
          index: config.opensearchIndex,
        };
        return axiosRequest({
          signal: signal,
          gateway: config.opensearchAPI.URL,
          endpoint: "tools-search/enhance/cim",
          api_key: user.api_key,
          method: "get",
          params: params,
        });
      } else if (product?.product_cim_id) {
        return axiosRequest({
          signal: signal,
          gateway: config.shopAPI.URL,
          endpoint: "products/full",
          api_key: user.api_key,
          method: "get",
          params: {
            id: product?.product_cim_id,
          },
        });
      } else {
        return null;
      }
    },
    enabled: !!product?.product_number && !!product?.product_cim_code,
  });

  //CIM product enhancement
  useEffect(() => {
    //update to new product object
    const createProduct = async (cim_data, categories) => {
      const clone = structuredClone(cim_data);
      if (clone?.Category && categories) {
        let cat = categories.flatCats.find(
          (category) => category.ID === clone.Category
        );
        //console.log(cat)
        if (cat?.Image) {
          clone.Graphics.push({
            URL: cat.Image,
            Type: "CategoryTechincalDiagram",
          });
        }
        //make the order of the images consistent
        //Photos first
        let photos = clone.Graphics.filter((image) => image.Type === "Photo");
        //CategoryTechincalDiagram second
        let techincalDiagram = clone.Graphics.filter(
          (image) => image.Type === "CategoryTechincalDiagram"
        );
        //then the rest
        let rest = clone.Graphics.filter(
          (image) =>
            image.Type !== "Photo" && image.Type !== "CategoryTechincalDiagram"
        );
        clone.Graphics = [...photos, ...techincalDiagram, ...rest];
      }
      //console.log(clone)
      let productObject = await createProductObject([
        { ...clone, data: "cim" },
      ]);
      setSelectedProduct((current) => ({
        ...current,
        ...productObject,
      }));
    };
    if (cim_data && categories) {
      createProduct(cim_data, categories);
    }
  }, [cim_data, categories]);

  //set units
  const [detailProfile, setDetailProfile] = useState(null);

  //fetch detail profile
  const get_detail_profile = useMutation(
    (form_id) => {
      return axiosRequest({
        gateway: config.shopAPI.URL,
        endpoint: "categories/parametric",
        api_key: user.api_key,
        method: "get",
        params: {
          form_id: form_id,
        },
      });
    },
    {
      onSuccess: (data) => {
        setDetailProfile(data);
      },
    }
  );

  useEffect(() => {
    if (cim_data?.Category && categories.flatCats) {
      //get detail profile
      let cat = categories.flatCats.find(
        (category) => category.ID === cim_data.Category
      );
      if (cat) {
        get_detail_profile.mutate(cat.DetailProfile);
      }
    }
  }, [cim_data?.Category, categories.flatCats]);

  //add units of measure to product attributes
  useEffect(() => {
    if (cim_data?.Properties && detailProfile?.Fields) {
      //set unit of measure
      let attributes = structuredClone(cim_data.Properties);

      attributes = attributes.map((property, i) => {
        //find attribute in detail profile
        let detail = detailProfile.Fields.find(
          (detail) => detail.Ref === property.DIN
        );
        let info = detail?.Values.find((value) => value.ID === property.Value);
        return {
          id: i,
          code: property?.DIN,
          name: property?.Label,
          value: property?.Value,
          unit: detail?.Unit || "",
          info: info?.Name || "",
        };
      });
      setSelectedProduct((current) => ({
        ...current,
        product_attributes: attributes,
      }));
    }
  }, [detailProfile, cim_data?.product_attributes]);

  return {
    cim_isFecthing: cim_isFecthing,
  };
}
