import React, { useEffect, useRef, useState } from "react";
import { Alert } from "@mui/material";

export default function StagingSite({ fixed }) {
  const [show, setShow] = useState();
  const alertRef = useRef(null);

  useEffect(() => {
    //console.log(window.location.href);
    if (window.location.href.includes("toolingshop.co.uk")) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [window]);

  useEffect(() => {
    //add this to a event listener
    window.addEventListener("resize", () => {
      document.body.style.paddingBottom = `${alertRef?.current?.clientHeight}px`;
    });
    window.addEventListener("load", () => {
      document.body.style.paddingBottom = `${alertRef?.current?.clientHeight}px`;
    });
    //remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", () => {
        document.body.style.paddingBottom = `0px`;
      });
      window.removeEventListener("load", () => {
        document.body.style.paddingBottom = `0px`;
      });
    };
  }, []);

  return (
    <>
      {show && (
        <Alert
          ref={alertRef}
          variant="filled"
          severity="error"
          sx={{
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 1000,
            borderRadius: 0,
            padding: "1em",
            position: fixed ? "fixed" : "relative",
          }}
        >
          THIS IS A STAGING SITE
        </Alert>
      )}
    </>
  );
}
