import React, { useContext, useState, useEffect } from "react";
import { Box, Typography, Grid, Fade } from "@mui/material";
import Parametric from "./areas/Parametric";
import Application from "./areas/Application";
import Design from "./areas/Design";
import Coolantcare from "./areas/Coolantcare";
import KPI from "./areas/KPI";
import Vend from "./areas/Vend";
import Resources from "./areas/Resources";
import { isMobileOnly } from "react-device-detect";
import { UserContext } from "../store/UserContext";
import { LayoutContext } from "../store/LayoutContext";
import CNC from "./areas/CNC";
import Fixtures from "./areas/Fixtures";
import getAccessID from "../helpers/getAccessID";
import { useNavigate } from "react-router-dom";
import { RESET_ALL_FILTERS } from "../store/SelectedReducers";
import { UPDATE_MENU } from "../store/LayoutReducers";
import { SelectedContext } from "../store/SelectedContext";

function Cards({ title, slug, image, subtitle, disabled }) {
  const { app, dispatchApp } = useContext(LayoutContext);
  const { user } = useContext(UserContext);
  const { dispatchSelected } = useContext(SelectedContext);
  const [access, setAccess] = useState();
  const navigate = useNavigate();
  const [item, setItem] = useState();

  //find the link fromthe menu object
  useEffect(() => {
    if (app.menu) {
      //console.log(app.menu)
      let menu = app.menu.find((item) => item.area === slug);
      if (menu) {
        setItem(menu);
      }
      setAccess(getAccessID(user?.user?.user_permissions?.[`${slug}`]?.access));
    }
  }, [slug, user]);

  return (
    <Box
      sx={{
        cursor: access === 1 && !disabled ? "pointer" : "inherit",
        position: "relative",
        padding: "1em",
        paddingRight: "200px",
        background: "#ddd",
        overflow: "hidden",
        height: "100%",
        position: "relative",
        borderRadius: "5px",
        color: (theme) => theme.palette.text.primary,
        "&:hover .hover ": {
          opacity: access === 1 && !disabled ? 0.4 : 0,
        },
      }}
      onClick={() => {
        if (access === 1 && !disabled) {
          //reset all filters
          dispatchSelected({
            type: RESET_ALL_FILTERS,
          });
          //console.log(area,menu)
          dispatchApp({
            type: UPDATE_MENU,
            payload: {
              selectedArea: item.area,
              selectedMenu: item.menu,
              selectedSubMenu: item.submenu,
            },
          });
          navigate(item.link);
        }
      }}
    >
      <Box
        className="hover"
        sx={{
          cursor: access === 1 ? "pointer" : "inherit",
          background: (theme) => theme.palette.primary.main,
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          transition: "all 0.3s ease",
        }}
      />
      <Typography
        variant={isMobileOnly ? "h6" : "h4"}
        component="h2"
        sx={{
          marginTop: isMobileOnly ? "0.5em" : "1em",
          marginBottom: "10px",
          position: "relative",
          zIndex: 2,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant={isMobileOnly ? "p" : "h6"}
        component="body1"
        sx={{
          maxWidth: "240px",
          position: "relative",
          zIndex: 2,
        }}
      >
        {subtitle}
      </Typography>
      <Box
        sx={{
          position: "absolute",
          right: "0",
          bottom: "-50px",
        }}
      >
        {image}
      </Box>
    </Box>
  );
}

export default function CISLandingOptions() {
  return (
    <Grid container spacing={2} alignContent="flex-start">
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight={600}
          sx={{
            marginTop: "1em",
            padding: "0.5em 1em",
            float: "left",
            background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            content: '""',
            clipPath:
              "polygon(0 0, 100% 0, 100% 0, calc(100% - 20px) 100%, 0 100%)",
            paddingRight: "2.5em",
          }}
        >
          Tooling Selector
        </Typography>
      </Grid>
      <Grid item container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="Parametric"
            slug="parametric"
            subtitle="I know the specific parameters?"
            image={
              <Parametric
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="Application"
            slug="application"
            subtitle="I know the application dimensions?"
            image={
              <Application
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="Custom Tool"
            slug="design"
            subtitle="No standard products design something custom"
            image={
              <Design
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight={600}
          sx={{
            marginTop: "1em",
            padding: "0.5em 1em",
            float: "left",
            background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            content: '""',
            clipPath:
              "polygon(0 0, 100% 0, 100% 0, calc(100% - 20px) 100%, 0 100%)",
            paddingRight: "2.5em",
          }}
        >
          Data Management
        </Typography>
      </Grid>
      <Grid item container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="Vending"
            slug="vend"
            subtitle="Control inventory, report & manage consumption data"
            image={
              <Vend
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="MWF"
            slug="coolantcare"
            subtitle="Monitor & Manage oils & lubricants condition & performance"
            image={
              <Coolantcare
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="CVR"
            slug="kpi"
            subtitle="Customer Value Record records the instances of time and cost savings provided"
            image={
              <KPI
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h2"
          fontWeight={600}
          sx={{
            marginTop: "1em",
            padding: "0.5em 1em",
            float: "left",
            background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            content: '""',
            clipPath:
              "polygon(0 0, 100% 0, 100% 0, calc(100% - 20px) 100%, 0 100%)",
            paddingRight: "2.5em",
          }}
        >
          Asset Management
        </Typography>
      </Grid>
      <Grid item container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="Maintenance"
            // slug="solution"
            subtitle="Factory maintenance scheduler and record of completion"
            image={
              <Fixtures
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                  opacity: "0.2!important",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="Track & Trace"
            //slug="cnc"
            subtitle="Setting, and monitoring tool dimensions & location in the factory"
            image={
              <CNC
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                  //opacity: '0.2!important'
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Cards
            title="Resources"
            slug="resources"
            subtitle="Access all product and reference documentation and materials"
            image={
              <Resources
                noClick
                svgprops={{
                  position: "relative",
                  width: isMobileOnly ? "160px" : "200px",
                  height: isMobileOnly ? "160px" : "200px",
                }}
                textprops={{
                  display: "none",
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
