import React, { useState } from "react";
import { Typography } from "@mui/material";
import config from "../../config";

const BrandLogo = ({ product, grey }) => {
  const [imageError, setImageError] = useState(false);

  const imageUrl = product?.product_manufacturer
    ? `${config?.shopS3?.BUCKET}public/brand-logos/${product.product_manufacturer}`
    : product?.product_brand_url;

  return (
    <>
      {imageUrl && !imageError ? (
        <img
          alt={product?.product_manufacturer}
          src={imageUrl}
          style={{
            filter: grey ? "grayscale(100%)" : "none",
            opacity: grey ? 0.1 : 1,
            width: "100%",
            height: "100%",
            maxWidth: grey ? "80%" : "100px",
            maxHeight: grey ? "80%" : "auto",
            objectFit: "contain",
            margin: grey ? "5% auto" : "0",
            display: imageError ? "none" : "block",
          }}
          onError={() => setImageError(true)}
        />
      ) : (
        <Typography variant="caption" sx={{ padding: "5px" }}>
          {product?.product_manufacturer || "No Image Available"}
        </Typography>
      )}
    </>
  );
};

export default BrandLogo;
