import React, { useContext, useEffect, useState } from "react";
import CoolantDefaults from "./CoolantDefaults";
import { SAVE_SELECTED_COOLANT } from "../../store/CoolantReducers";
import { CoolantContext } from "../../store/CoolantContext";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import { buttonStyles } from "../../styles/buttonStyles";
import RefreshCoolant from "./RefreshCoolant";
import Frequency from "./Frequency";
import { isMobileOnly } from "react-device-detect";
import formatDate from "../../helpers/formatDate";
import setLanguageText from "../../../language/setLanguageText";
import { UserContext } from "../../../store/UserContext";
import { LayoutContext } from "../../../store/LayoutContext";
import calcOverdueCoolant from "../../helpers/calcOverdueCoolant";
import getUserLevel from "../../../helpers/getUserLevel";
import { useQuery } from "@tanstack/react-query";
import axiosRequest from "../../../axios/axoisRequest";
import config from "../../../config";
import AddCoolant from "./AddCoolant";

export default function CoolantSettings({ selectedMachine }) {
  const { user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const [expanded, setExpanded] = useState(false);
  const buttonStyle = buttonStyles();

  const handleExpand = () => {
    setExpanded((state) => setExpanded(!state));
  };

  const { dispatchSelectedCoolant, selectedCoolant } =
    useContext(CoolantContext);

  //get coolants based on what compnay has access to
  const { data: coolant } = useQuery({
    queryKey: ["all coolants", selectedMachine?.coolant_id],
    queryFn: ({ signal }) =>
      axiosRequest({
        signal: signal,
        gateway: config.coolantAPI.URL,
        endpoint: "coolants",
        api_key: user.api_key,
        method: "get",
        params: {
          product_id: selectedMachine.coolant_id,
        },
      }),
    enabled: !!selectedMachine?.coolant_id,
    retry: false,
  });

  useEffect(() => {
    //console.log(selectedMachine)
    if (coolant) {
      dispatchSelectedCoolant({
        type: SAVE_SELECTED_COOLANT,
        payload: coolant[0],
      });
    } else {
      dispatchSelectedCoolant({
        type: SAVE_SELECTED_COOLANT,
        payload: null,
      });
    }
  }, [coolant]);

  return (
    <Accordion expanded={expanded} onChange={handleExpand}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="machine-details"
        id="machine-details"
        disabled={!selectedMachine?.coolant_id}
      >
        {" "}
        <Typography variant={isMobileOnly ? "p" : "h6"}>
          {setLanguageText("Product")}
          {!isMobileOnly && " Details"}:{" "}
          <span style={{ color: "#888" }}>
            {selectedCoolant?.product_name
              ? selectedCoolant?.product_name
              : setLanguageText("No Product")}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          borderTop: "1px solid #ececec",
          padding: 0,
        }}
      >
        <Grid container item>
          {selectedMachine?.coolant_id === -1 && (
            <Grid
              item
              xs={12}
              sx={{
                padding: "1em",
              }}
            >
              <AddCoolant selectedMachine={selectedMachine} hideButton />
            </Grid>
          )}
          {selectedCoolant && (
            <>
              <Grid
                container
                item
                sx={{
                  margin: "1em 1em -.5em 1em",
                }}
                spacing={1}
              >
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>{setLanguageText("Last Product Change")}: </strong>
                    {formatDate(selectedMachine.last_coolant_change)}
                  </Typography>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  margin: "1em 1em -.5em 1em",
                }}
              >
                {selectedMachine?.machine_settings?.machine_defaults
                  ?.change_freq
                  ? calcOverdueCoolant(
                      selectedMachine.last_coolant_change,
                      selectedMachine.machine_settings.machine_defaults
                        .change_freq,
                      language
                    )
                  : null}
              </Grid>

              {selectedMachine?.machine_settings?.admin?.operator_email
                ?.name ? (
                <Grid
                  container
                  item
                  sx={{
                    margin: "1em 1em -.5em 1em",
                  }}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Paper className={`${buttonStyle.box}`} variant="outlined">
                      <Typography variant="body1" align="center">
                        <strong>{setLanguageText("Operator")}: </strong>
                        {
                          selectedMachine?.machine_settings?.admin
                            ?.operator_email?.name
                        }{" "}
                        -{" "}
                        {
                          selectedMachine?.machine_settings?.admin
                            ?.operator_email?.email
                        }
                        {/* {`PH Max: ${defaults.ph_max}`} */}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={`${buttonStyle.box}`} variant="outlined">
                      <Typography variant="body1" align="center">
                        <strong>
                          {setLanguageText("Operators Company")}:{" "}
                        </strong>
                        {
                          selectedMachine?.machine_settings?.admin
                            ?.operator_email?.company_name
                        }
                        {/* {`PH Max: ${defaults.ph_max}`} */}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}

              <Grid
                item
                container
                spacing={1}
                style={{
                  margin: "1em 1em 0 1em",
                }}
              >
                {getUserLevel(user.user, "coolantcare", "machine_edit", 6) ? (
                  <>
                    <RefreshCoolant
                      selectedCoolant={selectedCoolant}
                      selectedMachine={selectedMachine}
                    />
                    <Frequency
                      edit={
                        selectedMachine?.machine_settings?.admin ? true : false
                      }
                    />
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {selectedCoolant && (
                  <CoolantDefaults coolant={selectedCoolant} />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
