import React, { useContext, useEffect } from "react";
import { Alert, AppBar, Box, Collapse, IconButton } from "@mui/material";
import { LayoutContext } from "../store/LayoutContext";
import CloseIcon from "@mui/icons-material/Close";

export default function Banner({ fixed }) {
  const { theme } = useContext(LayoutContext);
  const [open, setOpen] = React.useState(true);
  const [paddingBottom, setPaddingBottom] = React.useState();
  const alertRef = React.useRef(null);

  useEffect(() => {
    //add this to a event listener
    window.addEventListener("resize", () => {
      document.body.style.paddingBottom = `${alertRef?.current?.clientHeight}px`;
    });
    window.addEventListener("load", () => {
      document.body.style.paddingBottom = `${alertRef?.current?.clientHeight}px`;
    });
    //remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", () => {
        document.body.style.paddingBottom = `0px`;
      });
      window.removeEventListener("load", () => {
        document.body.style.paddingBottom = `0px`;
      });
    };
  }, []);

  useEffect(() => {
    if (!open) {
      //reset the padding bottom
      document.body.style.paddingBottom = `0px`;
    }
  }, [open]);

  return (
    <>
      {theme?.app_settings?.show_banner && (
        <Collapse in={open}>
          <Alert
            ref={alertRef}
            variant="filled"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 1000,
              borderRadius: 0,
              padding: "1em",
              position: fixed ? "fixed" : "relative",
            }}
          >
            {theme.app_settings.banner_text}
          </Alert>
        </Collapse>
      )}
    </>
  );
}
