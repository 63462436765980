//-------------------------------------------------------------------------------------
// CIS CONFIG - LIVE
//-------------------------------------------------------------------------------------

const stage = process.env.NODE_ENV !== "production" ? "dev" : "live";

const config = {
  global: {
    REGION: "eu-west-1",
  },
  ezbaseAPI: {
    URL: `https://317y83xpc0.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Factory Specific API
  },
  factoryAPI: {
    URL: `https://y7ooats8k6.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Factory Specific API
  },
  coolantAPI: {
    URL: `https://c8zvs6pvn0.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Coolant care
  },
  shopAPI: {
    URL: `https://nd4lmfkref.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Shop Specific API
  },
  solutionAPI: {
    URL: `https://5v64z1msp8.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Solutions Specific API
  },
  fixturesAPI: {
    URL: `https://t3sw9jpkfk.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Fixtures Specific API
  },
  designAPI: {
    URL: `https://y864rpcs0b.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Design Specific API
  },
  erpAPI: {
    URL: `https://fcxq5kjo84.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - ERP Specific API
  },
  opensearchAPI: {
    URL: `https://pjc8cd08kb.execute-api.eu-west-1.amazonaws.com/${stage}`, //CIS - Opensearch
  },
  opensearchIndex: "cis-tools-index",
  shopS3: {
    REGION: "eu-west-1",
    BUCKET:
      "https://amplify-dtukrcr695qrm-mai-productdatabucket428dbc2-mwfpljioizeb.s3.eu-west-1.amazonaws.com/",
  },
  s3: {
    REGION: "eu-west-1",
    BUCKET: "cis-smartfactory-coolantcare",
  },
  solutionS3: {
    REGION: "eu-west-1",
    BUCKET: "cis-smartfactory-solution",
  },
  fixturesS3: {
    REGION: "eu-west-1",
    BUCKET: "cis-smartfactory-fixture",
  },
  designS3: {
    REGION: "eu-west-1",
    BUCKET: "cis-smartfactory-designs",
  },
  brandLogos: {
    REGION: "eu-west-1",
    BUCKET: "cis-brand-logos",
  },
  LANGUAGE: { label: "English", id: "EN" },
  MAX_ATTACHMENT_SIZE: 5000000,
  META_DESCRIPTION: "For all of SmartFactory™ needs.",
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_lNffFpcti",
    APP_CLIENT_ID: "7b6vohg56d3sb7p7l5s9rfc6d0",
    IDENTITY_POOL_ID: "eu-west-1:e4850707-0c60-4612-8731-bce3e063e50a",
  },
  COLOURMODE: "light", // default colour mode
  APPNAME: "cis",
  APPID: 1,
  APPTITLE: "SmartFactory™",
  SALTING: "MUSIC ELBOW FATIQUE RULER",
  INSTANCE: "prod",
  TIDIO: "//code.tidio.co/mgxsa3x2vm75udna9rc0vuztge5xfbsb.js",
  TIDIOCHAT: "https://www.tidio.com/talk/mgxsa3x2vm75udna9rc0vuztge5xfbsb",
  ANALYTICS: "G-1PWF80N6E1",
  //CLARITY: 'hob7dqhkqu'
};

export default config;
