import {
	Tab,
	Grid,
	Button,
	Alert,
	Box
} from '@mui/material';
import React, {
	useEffect,
	useContext,
	useState
} from 'react';
import CompanySettings from './companies/CompanySettings';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../store/UserContext';
import setLanguageText from '../language/setLanguageText';
import CoolantSettings from './coolant/CoolantSettings';
import PageContent from '../coolantcare/PageContent';
import InnerHeaderWrap from '../layout/InnerHeaderWrap';
import GlobalCompanySelect from '../reusable/GlobalCompanySelect';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddCompany from './companies/AddCompany';
import Visibility from './companies/visibility/Visibility';
import { CompanyContext } from '../store/CompanyContext';
import getAccessID from '../helpers/getAccessID';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdminSettings from './companies/admin/AdminSettings';


const Settings = () => {

	const navigate = useNavigate();
	const { selectedCompany } = useContext(CompanyContext)
	const { user } = useContext(UserContext)
	const [value, setValue] = useState(0);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const section = params.get('section')
		if (section) {
			setValue(parseInt(section))
		}
	}, [])

	const handleChange = (event, newValue) => {
		//console.log(newValue)
		navigate(`/settings/company?section=${newValue}&tab=0`)
		setValue(newValue);
	};

	const [companyOpen, setCompanyOpen] = useState(false);
	const openCompany = e => {
		setCompanyOpen(true);
	};
	
	const closeCompany = () => {
		setCompanyOpen(false)
	};

	return (
		<>
			<InnerHeaderWrap>
				<Grid container spacing={2} alignItems="center" alignContent="center"justifyContent="space-between" >
					<Grid item xs={3} >
						<GlobalCompanySelect fullWidth />
					</Grid>
					{user.user.user_permissions.administrator ?
						<Grid item xs>
							<Button
								onClick={openCompany}
								variant="contained"
								color="primary"
								size='large'
								startIcon={<FontAwesomeIcon icon={faPlus} />}
							>
								{setLanguageText("Add Company")}
							</Button>
						</Grid>
						: null}
				</Grid>
				<AddCompany setIsDialog={closeCompany} open={companyOpen} />

			</InnerHeaderWrap>
			<PageContent>
				{selectedCompany.company_id === 0 ?
					<Alert severity="warning">
						{setLanguageText("Please select a company to edit settings")}
					</Alert>
					:
					<Box
						sx={{
							'& .MuiTabPanel-root': {
								padding: 0,
							}
						}}>
						<TabContext value={value}>
							<TabList
								//variant="fullWidth"
								centered
								onChange={handleChange}
								sx={{
									marginBottom: 2,
								}}
							>
								<Tab label={setLanguageText("Company Settings")} value={0} />
								{/* {getAccessID(user.user.user_permissions?.coolantcare?.access) === 1 &&
									<Tab label={setLanguageText("Coolantcare")} value={1} />
								} */}
								{user.user.user_permissions.administrator ?
									<Tab label={setLanguageText("Systems Administrator Settings")} value={2} />
									: null}
							</TabList>
							<TabPanel value={0} >
								<CompanySettings />
							</TabPanel>
							{/* {getAccessID(user.user.user_permissions.coolantcare.access) === 1 &&
								<TabPanel value={1} >
									<CoolantSettings />
								</TabPanel>
							} */}
							{user.user.user_permissions.administrator ?
								<TabPanel value={2} >
									{/* <Visibility /> */}
									<AdminSettings />
								</TabPanel>
								: null}
						</TabContext>
					</Box>

				}
			</PageContent>
		</>
	);
}

export default Settings;
