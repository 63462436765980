import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Backdrop,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Fade,
  Grid,
  Skeleton,
} from "@mui/material";
import axiosRequest from "../../axios/axoisRequest";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { faImageSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductDetails from "./ProductDetails";
import { useParams } from "react-router-dom";
import ProductTabs from "../shared/tabs/ProductTabs";
import { isMobileOnly } from "react-device-detect";
import SingleImage from "../../shop/reusable/SingleImage";
import ExportSingleProduct from "../shared/ExportSingleProduct";
import SpinningLoader from "../../reusable/SpinnningLoader";
import ProductSlider from "../../reusable/ProductSlider";
import useEnhancedCIMFull from "../../hooks/useEnhancedCIMFull";
import InnerContentWrap from "../../layout/InnerContentWrap";
import SearchHeader from "../../search/SearchHeader";
import BrandLogo from "../../shop/reusable/BrandLogo";
import AddToFavourites from "../../favourites/AddToFavourites";
import useEnhancedEzbase from "../../hooks/useEnhancedEzbase";
import { BrandsContext } from "../../store/BrandsContext";
import createSingleProduct from "./createProduct";
import Prices from "../shared/Prices";
import Delivery from "../shared/Delivery";
import AddToBasket from "../../basket/AddToBasket";
import { CompanyContext } from "../../store/CompanyContext";
import { useQuery } from "@tanstack/react-query";
import useEnhancedBrand from "../../hooks/useEnhancedBrand";
import useGetPrices from "../../hooks/useGetPrices";
//import AddToBasket from "../../basket/AddToBasket";
//import ProductAdd from "./ProductAdd";

export default function ProductFullPage() {
  const { user } = useContext(UserContext);
  //const { selectedCompany } = useContext(CompanyContext);
  const [selectedProduct, setSelectedProduct] = useState();
  const [product, setProduct] = useState();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);
  const { brands } = useContext(BrandsContext);
  // const ACTstoredToken = sessionStorage.getItem('ACTtoken');
  // const CISstoredToken = sessionStorage.getItem('CIStoken');
  const { cim_isFecthing, allAccessories, categoryImage } = useEnhancedCIMFull({
    selectedProduct,
    setSelectedProduct,
  });
  const { ezbase_isFecthing } = useEnhancedEzbase({
    setSelectedProduct,
    product,
  });
  const { brand_isFetching } = useEnhancedBrand({
    setSelectedProduct,
    product,
  });
  const { isFetchingPrice } = useGetPrices({
    productDetails: product,
    setProductDetails: setSelectedProduct,
  });

  const { data, isFetching } = useQuery({
    queryKey: ["product", id],
    queryFn: ({ signal }) =>
      axiosRequest({
        signal: signal,
        gateway: config.opensearchAPI.URL,
        endpoint: "tools-search",
        api_key: user.api_key,
        method: "post",
        body: {
          id: id,
          index: config.opensearchIndex,
        },
      }),
    enabled: !!id,
    retry: 1,
  });

  //create product object
  useEffect(() => {
    if (data?.hits?.hits) {
      let product = data.hits.hits[0];
      let selected = createSingleProduct({ product, brands });
      setSelectedProduct(selected);
      setProduct(selected);
    }
  }, [data]);

  // const { data: price } = useQuery({
  //     queryKey: ['price', selectedProduct?.product_supplier],
  //     queryFn: () => {
  //         let params = {
  //             "supplier": selectedProduct?.product_supplier,
  //             "account": selectedCompany?.company_erp.AccountNumber,
  //             "token": config.APPNAME === "act" ? ACTstoredToken : CISstoredToken || null
  //         }
  //         if (selectedProduct?.product_code) {
  //             params = {
  //                 ...params,
  //                 "code": selectedProduct.product_code
  //             }
  //         }
  //         if (selectedProduct?.product_id) {
  //             params = {
  //                 ...params,
  //                 "id": selectedProduct.product_id
  //             }
  //         }
  //         return (
  //             axiosRequest({
  //                 gateway: config.opensearchAPI.URL,
  //                 endpoint: 'tools-search/price',
  //                 api_key: user.api_key,
  //                 method: 'get',
  //                 params: params
  //             })
  //         )
  //     },
  //     enabled: !!selectedProduct?.product_supplier && !!selectedCompany?.company_erp?.AccountNumber,
  //     retry: 1
  // })

  // //create price
  // useEffect(() => {
  //     if (price) {
  //         let priceObject
  //         if (!price?.errorMessage) {
  //             //console.log(data?.errorMessage)
  //             priceObject = {
  //                 //"product_id": data?.ArticleNumber, //need this for favourites
  //                 "price_rrp": price?.PriceRRP,
  //                 "price_incl_discount": price?.PriceInclDiscount,
  //                 "price_discount": price?.PriceDiscount,
  //                 "product_stock": price?.TotalStock,
  //                 "product_free_stock": price?.FreeStock,
  //                 "product_erp_code": price?.ArticleNumber,
  //                 "product_erp": price?.FullProduct,
  //             }
  //         } else {
  //             priceObject = {
  //                 "error": price?.errorMessage,
  //             }
  //         }
  //         setSelectedProduct(current => ({
  //             ...current,
  //             ...priceObject
  //         }))
  //     }
  // }, [price])

  return (
    <>
      <SearchHeader
        link={true}
        large={true}
        setValue={setValue}
        value={value}
        setFocused={setFocused}
        focused={focused}
        backdrop={true}
        //props={props}
      />
      <InnerContentWrap>
        <Card>
          <Grid item container>
            <Grid
              item
              xs={12}
              md={6}
              container
              alignContent="center"
              sx={{ position: "relative" }}
            >
              <Fade in={true}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: isMobileOnly ? "1em" : "2em",
                    background: "#fff",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 16,
                      left: 16,
                      zIndex: 2,
                    }}
                  >
                    <AddToFavourites
                      product={selectedProduct}
                      isFetching={isFetching}
                      big
                    />
                  </Box>
                  {/* {cim_isFecthing || ezbase_isFecthing ?
                                        <Box sx={{
                                            height: '100%',
                                            width: '100%',
                                            position: 'absolute',
                                            zIndex: 1,
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'grid',
                                            top: 0,
                                            left: 0
                                        }}>
                                            <SpinningLoader text={"Enhancing data..."} size={100} />
                                        </Box>
                                        : null} */}
                  {selectedProduct?.product_image &&
                  selectedProduct?.product_image?.length > 1 ? (
                    <ProductSlider
                      images={selectedProduct.product_image}
                      url="url"
                      descrition="TYPE"
                    />
                  ) : selectedProduct?.product_image &&
                    selectedProduct?.product_image.length === 1 ? (
                    <SingleImage
                      imageUrl={selectedProduct.product_image[0].url}
                      alt={selectedProduct.product_name}
                      icon="6x"
                      padding="1em"
                    />
                  ) : cim_isFecthing ||
                    ezbase_isFecthing ||
                    brand_isFetching ||
                    isFetching ? (
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        zIndex: 1,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "grid",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <SpinningLoader text={"Enhancing data..."} size={100} />
                    </Box>
                  ) : (
                    <Fade in={true}>
                      <Box
                        sx={{
                          height: "100%",
                          width: "100%",
                          position: isMobileOnly ? "relative" : "absolute",
                          zIndex: 2,
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "grid",
                          top: 0,
                          left: 0,
                          svg: {
                            margin: "0 auto",
                          },
                        }}
                      >
                        <BrandLogo product={selectedProduct} grey={true} />
                      </Box>
                    </Fade>
                  )}
                </Grid>
              </Fade>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              sx={{ padding: "1em" }}
              alignItems="space-between"
            >
              <Grid
                container
                item
                xs={12}
                spacing={2}
                sx={{ marginTop: isMobileOnly ? "0" : "16px" }}
              >
                <Grid item xs={12}>
                  {selectedProduct ? (
                    <Typography color="text.primary" variant="h5">
                      {selectedProduct?.product_name}
                    </Typography>
                  ) : (
                    <Skeleton width="100%" height={80} />
                  )}
                </Grid>
                {selectedProduct ? (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: "250px",
                        height: isMobileOnly ? "60px" : "80px",
                        overflow: "hidden",
                      }}
                    >
                      <BrandLogo product={selectedProduct} />
                    </Box>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  {/* <product product={full} isFetching={isFetching}  /> */}
                  <ProductDetails
                    product={selectedProduct}
                    isFetching={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  {selectedProduct ? (
                    <Grid container spacing={1 / 2}>
                      <Grid item xs={12}>
                        <Prices product={selectedProduct} />
                      </Grid>
                      <Grid item xs={12}>
                        <Delivery large={true} product={selectedProduct} />
                      </Grid>
                      <Grid item xs={12}>
                        <AddToBasket
                          product={selectedProduct}
                          area="opensearch"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Skeleton
                      width="100%"
                      height={80}
                      sx={{ marginBottom: 0 }}
                    />
                  )}
                  <Divider variant="middle" sx={{ margin: "1em auto" }} />
                </Grid>
              </Grid>

              <Grid
                item
                container
                spacing={1}
                xs={12}
                textAlign="right"
                justifyContent="flex-end"
              >
                <Grid item>
                  <ExportSingleProduct product={selectedProduct} />
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                background: (theme) => theme.palette.cards.header,
              }}
            >
              <ProductTabs
                isFetching={cim_isFecthing || ezbase_isFecthing}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                allAccessories={allAccessories}
                categoryImage={categoryImage}
              />
            </Box>
          </Grid>
        </Card>
      </InnerContentWrap>
    </>
  );
}
