import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, Grid, Skeleton } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import config from "../../config";
import { UserContext } from "../../store/UserContext";
import { useParams } from "react-router-dom";
import InnerContentWrap from "../../layout/InnerContentWrap";
import { CategoryContext } from "../../store/CategoryContext";
import { isMobileOnly } from "react-device-detect";
import { faImageSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExportSingleProduct from "../shared/ExportSingleProduct";
import BrandLogo from "../../shop/reusable/BrandLogo";
import ProductDetails from "./ProductDetails";
import AddToBasket from "../../basket/AddToBasket";
import ProductTabs from "./ProductTabs";
import createProductObject from "../../helpers/createProductObject";
import ProductSlider from "../../reusable/ProductSlider";
import SingleImage from "../../shop/reusable/SingleImage";
import axiosRequest from "../../axios/axoisRequest";
import { LayoutContext } from "../../store/LayoutContext";
import AddToFavourites from "../../favourites/AddToFavourites";
import SearchHeader from "../../search/SearchHeader";
import { BrandsContext } from "../../store/BrandsContext";
import Prices from "../shared/Prices";
import Delivery from "../shared/Delivery";
import useGetPrices from "../../hooks/useGetPrices";
import createSingleProduct from "../opensearch/createProduct";

const ProductFullPage = (props) => {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const { categories } = useContext(CategoryContext);
  const { brands } = useContext(BrandsContext);
  const { app } = useContext(LayoutContext);
  const [categoryImage, setCategoryImage] = useState();
  const [allAccessories, setAllAccessories] = useState();
  const [noImage, setNoImage] = useState(false);
  const [erpDetails, setErpDetails] = useState();
  const [productDetails, setProductDetails] = useState();
  const [detailProfile, setDetailProfile] = useState();
  const [focused, setFocused] = useState(false);
  const { isFetchingPrice } = useGetPrices({
    productDetails,
    setProductDetails,
  });

  const { data, isFetching } = useQuery(
    ["products", id],
    ({ signal }) =>
      axiosRequest({
        signal: signal,
        gateway: config.shopAPI.URL,
        endpoint: "products/full",
        api_key: user.api_key,
        method: "get",
        params: {
          id: id,
        },
      }),
    {
      enabled: !!id,
      notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
      retry: 1,
    }
  );

  //create product object
  useEffect(() => {
    if (productDetails?.product_cim_code && brands?.openSearchBrands) {
      //console.log(productDetails)
      //console.log(brands.openSearchBrands)

      let brand = brands.openSearchBrands.find(
        (brand) => brand?.supplier_code === productDetails.product_cim_code
      );
      console.log(brand);

      let productObject = {
        product_brand_url: brand?.brand_logo ? brand?.brand_logo : "",
        product_external_delivery: brand?.external_delivery
          ? brand?.external_delivery
          : "",
        product_local_delivery: brand?.local_delivery
          ? brand?.local_delivery
          : "",
      };
      setProductDetails((current) => ({ ...current, ...productObject }));
    }
  }, [productDetails?.product_cim_code, brands?.openSearchBrands]);

  useEffect(() => {
    //update to new product object
    const createProduct = async (product) => {
      const clone = structuredClone(product);
      //add category image to product first
      //console.log(product)

      if (product?.Category) {
        //console.log(categories.flatCats)
        let cat = categories.flatCats.find(
          (category) => category.ID === product.Category
        );
        //console.log(cat)
        if (cat?.Image) {
          clone.Graphics.push({
            URL: cat.Image,
            Type: "CategoryTechincalDiagram",
          });
          setCategoryImage({
            url: cat.Image,
            type: "CategoryTechincalDiagram",
          });
        } else {
          setNoImage(true);
        }
      }
      //console.log(clone)

      let productObject = await createProductObject([
        { ...clone, area: app.selectedArea, product_dataset: "cim" },
      ]);
      //console.log(productObject)
      let found = await brands.allBrands.find(
        (brand) => brand.ID === productObject[0].product_company
      );
      if (found) {
        // product.product_brand = { 'url': found.Icon, "name": found.Name }
        productObject[0].product_brand_url = found.Icon;
      }

      setProductDetails((current) => ({
        ...current,
        ...productObject[0],
      }));
    };
    if (data) {
      //console.log(product)
      createProduct(data);
    }
  }, [brands.allBrands, data, categories]);

  //fetch accessories
  const { data: accessories, isFetching: isFetchingAcc } = useQuery(
    ["accessories", id],
    ({ signal }) =>
      axiosRequest({
        signal: signal,
        gateway: config.shopAPI.URL,
        endpoint: "products/accessories",
        api_key: user.api_key,
        method: "get",
        params: {
          id: id,
        },
      }),
    {
      enabled: !!id,
      refetchOnWindowFocus: false, //todo set globally
      retry: 1,
    }
  );

  //fetch detail profile
  const get_detail_profile = useMutation(
    (form_id) => {
      return axiosRequest({
        gateway: config.shopAPI.URL,
        endpoint: "categories/parametric",
        api_key: user.api_key,
        method: "get",
        params: {
          form_id: form_id,
        },
      });
    },
    {
      onSuccess: (data) => {
        setDetailProfile(data);
      },
    }
  );

  useEffect(() => {
    if (data?.Category && categories.flatCats) {
      //get detail profile
      let cat = categories.flatCats.find(
        (category) => category.ID === data.Category
      );
      if (cat) {
        get_detail_profile.mutate(cat.DetailProfile);
      }
    }
  }, [data?.Category, categories.flatCats]);

  //fetch associated accessories
  useEffect(() => {
    //console.log(data)
    //console.log(price)
    if (accessories && !accessories.errorMessage) {
      setAllAccessories(accessories);
    }
  }, [accessories]);

  //add units of measure to product attributes
  useEffect(() => {
    if (productDetails && detailProfile) {
      //set unit of measure
      productDetails.product_attributes.forEach((attribute) => {
        //find attribute in detail profile
        let detail = detailProfile.Fields.find(
          (detail) => detail.Ref === attribute.code
        );
        if (detail) {
          attribute.unit = detail.Unit;
        }
      });
      console.log(productDetails);
    }
  }, [detailProfile, productDetails?.product_attributes]);

  return (
    <>
      <SearchHeader
        link={true}
        large={true}
        setFocused={setFocused}
        focused={focused}
        backdrop={true}
      />
      <InnerContentWrap>
        <Card>
          <Grid item container alignContent="center">
            <Grid
              item
              xs={12}
              md={6}
              container
              sx={{
                padding: isMobileOnly ? "1em" : "2em",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 16,
                  left: 16,
                  zIndex: 2,
                }}
              >
                <AddToFavourites
                  product={productDetails}
                  isFetching={isFetching}
                  big
                />
              </Box>
              <Grid
                item
                xs={12}
                sx={{
                  background: "#fff",
                  position: "relative",
                }}
              >
                {productDetails?.product_image &&
                productDetails?.product_image.length > 1 ? (
                  <ProductSlider
                    images={productDetails.product_image}
                    url="url"
                    descrition="type"
                  />
                ) : productDetails?.product_image &&
                  productDetails?.product_image.length === 1 ? (
                  <SingleImage
                    imageUrl={productDetails.product_image[0].url}
                    alt={productDetails.product_name}
                    icon="6x"
                    padding="1em"
                  />
                ) : productDetails?.product_image &&
                  productDetails?.product_image.length === 0 &&
                  noImage ? (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      zIndex: 2,
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "grid",
                      top: 0,
                    }}
                  >
                    <BrandLogo product={productDetails} />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              sx={{
                padding: isMobileOnly ? "1em" : "2em",
                //maxWidth: '500px',
              }}
            >
              <Grid
                container
                item
                xs={12}
                spacing={isMobileOnly ? 1 : 2}
                alignContent="flex-start"
              >
                <Grid item xs={12}>
                  {productDetails ? (
                    <Typography
                      color="text.primary"
                      variant="h5"
                      sx={{ maringBottom: "1em" }}
                    >
                      {productDetails.product_name}
                    </Typography>
                  ) : (
                    <Skeleton width="100%" height={80} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {productDetails ? (
                    <Box
                      sx={{
                        width: "300px",
                        height: isMobileOnly ? "200px" : "100px",
                        overflow: "hidden",
                      }}
                    >
                      <BrandLogo product={productDetails} />
                    </Box>
                  ) : null}
                </Grid>
                <ProductDetails
                  product={productDetails}
                  isFetching={isFetching}
                  erpDetails={erpDetails}
                />
                <Grid item xs={12}>
                  {productDetails ? (
                    // <AddToBasket
                    //     product={productDetails}
                    //     large={true}
                    //     area="shop"
                    //     erpDetails={erpDetails}
                    //     setErpDetails={setErpDetails}
                    // />
                    <Grid container spacing={1 / 2}>
                      <Grid item xs={12}>
                        <Prices product={productDetails} />
                      </Grid>
                      <Grid item xs={12}>
                        <Delivery product={productDetails} />
                      </Grid>
                      <Grid item xs={12}>
                        <AddToBasket product={productDetails} area="shop" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Skeleton
                      width="100%"
                      height={80}
                      sx={{ marginBottom: 0 }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ExportSingleProduct
                    product={productDetails}
                    erpDetails={erpDetails}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "100%",
              background: (theme) => theme.palette.cards.header,
            }}
          >
            <ProductTabs
              isFetching={isFetching}
              allAccessories={allAccessories}
              selectedProduct={productDetails}
              setSelectedProduct={setProductDetails}
              categoryImage={categoryImage}
              fullHeight
            />
          </Box>
        </Card>
      </InnerContentWrap>
    </>
  );
};
export default ProductFullPage;
