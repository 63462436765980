import React, { useEffect, useContext } from "react";
import config from "../config";
import { Route, Routes as Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { UserContext } from "../store/UserContext";
import { SAVE_COMPANIES, SELECTED_COMPANY } from "../store/CompanyReducers";
import { CompanyContext } from "../store/CompanyContext";
import { useMutation } from "react-query";
import axiosRequest from "../axios/axoisRequest";
import BuyerRoutesWrap from "./BuyerRoutesWrap";
import ShopRoutesWrap from "./ShopRoutesWrap";
import CoolantRoutesWrap from "./CoolantRoutesWrap";
import SolutionsRoutesWrap from "./SolutionsRoutesWrap";
import FixturesRoutesWrap from "./FixturesRoutesWrap";
import DesignRoutesWrap from "./DesignRoutesWrap";
import AdminRoutesWrap from "./AdminRoutesWrap";
import { useMediaQuery } from "@mui/material";
import KPIRoutesWrap from "../kpi/KPIRoutesWrap";
import ResourceRoutesWrap from "../resources/ResourceRoutesWrap";
import usePageTracking from "../hooks/usePageTracking";
import NoRoutesWrap from "../NoRoutesWrap";
import useAccessToken from "../hooks/useAccessToken";
import NotFound from "../routes/NotFound";
import NoAccess from "../routes/NoAccess";
import Preview from "../routes/Preview";
import Vend from "../vend/Vend";
import Resources from "../resources/Resources";
import BuyerCatalogueLayout from "../buyers/layout/BuyerCatalogueLayout";
//import BuyerProductFullPage from "../buyers/products/BuyerProductFullPage";
import BuyerSearchLayout from "../buyers/layout/BuyerSearchLayout";
import ApplicationSearch from "../shop/application/ApplicationSearch";
//import ProductFullPage from "../shop/products/ProductFullPage";
import CIMProductFullPage from "../products/cim/ProductFullPage";
import ProductGroupFullPage from "../products/ezbase/ProductGroupFullPage";
import Parametric from "../shop/parametric/Parametric";
import ComingSoon from "../shop/reusable/ComingSoon";
import Companies from "../settings/companies/Companies";
import Settings from "../settings/Settings";
import ApplicationSettings from "../settings/application/ApplicationSettings";
import Solutions from "../solutions/Solutions";
import SolutionsNew from "../solutions/SolutionsNew";
import SolutionsView from "../solutions/SolutionsView";
import Fixtures from "../fixtures/Fixtures";
import FixturesNew from "../fixtures/FixturesNew";
import FixturesView from "../fixtures/FixturesView";
import Design from "../design/Design";
import DesignNew from "../design/DesignNew";
import DesignView from "../design/DesignView";
import CadCam from "../cadcam/CadCam";
import KPI from "../kpi/KPI";
import UserSettings from "../users/UserSettings";
//import AddUserFullPage from "../users/AddUserFullPage";
import Account from "../account/Settings";
import Dashboard from "../coolantcare/dashboard/Dashboard";
import Machines from "../coolantcare/Machines/Machines";
//import Additives from '../coolantcare/Products/Additives/Additives';
//import Coolants from '../coolantcare/Products/Coolants/Coolants';
import Machine from "../coolantcare/Machines/MachineDetails/MachineDetails";
import Products from "../coolantcare/Products/Products";
import Reports from "../coolantcare/Reports/Reports";
import Diagnostic from "../coolantcare/Diagnostic/Diagnostic";
import CompanyAttributes from "../coolantcare/attributes/CompanyAttributes";
import Scanner from "../coolantcare/QRScanner/Scanner";
import Service from "../coolantcare/service/Service";
//import Maintenance from '../coolantcare/Maintenance/Maintenance';
import FullEntry from "../coolantcare/Entry/FullEntry";
import MobileLanding from "../coolantcare/Mobile/MobileLanding";
import DisplayView from "../coolantcare/Display/DisplayView";
import KPIDashboard from "../kpi/KPIDashboard";
//new layouts
import ParametricProductsLayout from "../shop/layouts/ParametricProductsLayout";
import MatchingProductsLayout from "../shop/layouts/MatchingProductsLayout";
import CatalogueLayout from "../shop/layouts/CatalogueLayout";
import { LayoutContext } from "../store/LayoutContext";
import { UPDATE_MENU } from "../store/LayoutReducers";
import Favourites from "../favourites/Favourites";
import getAccessID from "../helpers/getAccessID";
import Landing from "../landing/Landing";
import _Landing_old from "../landing/_Landing_old";
import FullSearch from "../search/FullSearch";
import ProductFullPage from "../products/opensearch/ProductFullPage";
import ProductsMatching from "../products/opensearch/ProductsMatching";
import SearchRoutesWrap from "./SearchRoutesWrap";
import { setUserParams } from "../google_analytics/ga";
import Chat from "../chat/Chat";
import CVRRoutesWrap from "../cvr/CVRRoutesWrap";
import CVRDashboard from "../cvr/CVR";
import CVR from "../cvr/CVR";

const Routes = () => {
  const { user } = useContext(UserContext);
  const { dispatchApp } = useContext(LayoutContext);
  const {
    selectedCompany,
    dispatchCompanies,
    companies,
    dispatchSelectedCompany,
  } = useContext(CompanyContext);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  //create custom history object
  const customHistory = createBrowserHistory();

  const props = {
    customHistory: customHistory,
  };

  //usePageTracking();
  useAccessToken();

  //only fire on first load
  useEffect(() => {
    let menu, area;
    if (window.location.pathname === "/") {
      area = "home";
      menu = "";
    } else {
      area = window.location.pathname.split("/")[1];
      menu = window.location.pathname.split("/")[2];
    }
    dispatchApp({
      type: UPDATE_MENU,
      payload: {
        selectedArea: area,
        selectedMenu: menu,
      },
    });
  }, []);

  //--------------------------- GET COMPAINES FROM COOLANTCARE  ------------------------------//

  const getUsersCompanies = useMutation(
    (user) => {
      //console.log(user)
      //console.log(config)
      let params = {
        companies_id: user.user.user_permissions.access.companies
          ? JSON.stringify(user.user.user_permissions.access.companies)
          : null,
      };
      if (
        user.user.user_permissions.access.all_companies ||
        user.user.user_permissions.administrator
      ) {
        params = {
          ...params,
          all_companies: true,
        };
      }

      return axiosRequest({
        method: "get",
        gateway: config.coolantAPI.URL,
        endpoint: "companies",
        api_key: user.api_key,
        params: params,
      });
    },
    {
      onSuccess: (data) => {
        //update companies
        //console.log(data)
        //check local stroarge of firt load
        dispatchCompanies({ type: SAVE_COMPANIES, payload: data });
        //console.log(data)
        //check local storage and if user has id in allowed compnaies
        const localID = sessionStorage.getItem("selectedCompany");
        const allowed = data.find(
          (company) => company.company_id === parseInt(localID)
        );
        //console.log(localID)
        //console.log(allowed)
        if (allowed) {
          full_details.mutate(allowed.company_id);
        } else {
          full_details.mutate(data[0].company_id); //get first compnay full details
        }
      },
    }
  );

  const full_details = useMutation(
    (company_id) => {
      //console.log(user)
      return axiosRequest({
        method: "get",
        gateway: config.coolantAPI.URL,
        endpoint: "companies",
        api_key: user.api_key,
        params: {
          id: company_id,
        },
      });
    },
    {
      onSuccess: (data) => {
        //update selected
        //console.log(data)
        dispatchSelectedCompany({
          type: SELECTED_COMPANY,
          payload: data[0],
        });
        //window.sessionStorage.setItem('selectedCompany', JSON.stringify(data[0]));
        window.sessionStorage.setItem("selectedCompany", data[0].company_id);
      },
    }
  );

  useEffect(() => {
    if (user) {
      getUsersCompanies.mutate(user);
    }
  }, [user]);

  //set user and company details for google analytics
  useEffect(() => {
    if (user?.user?.user_id && selectedCompany?.company_id) {
      setUserParams({
        user_id: user.user.user_id,
        company_id: selectedCompany.company_id,
      });
    }
    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //     dataLayer.push(arguments);
    // }
    // if (user?.user?.user_id && selectedCompany?.company_id) {
    //     gtag('set', {
    //         "user_id": user.user.user_id,
    //         "company_id": selectedCompany.company_id,
    //     });
    // }
  }, [user?.user?.user_id, selectedCompany?.company_id]);

  return (
    <Switch>
      {/* landing */}

      {/* Admin routes */}
      {/* {user?.user?.user_permissions?.administrator ? 
                <Route>*/}
      <Route
        path="/"
        title="Dashboard"
        exact
        element={
          <SearchRoutesWrap title="Dashboard" {...props}>
            <Landing {...props} />
          </SearchRoutesWrap>
        }
      />
      <Route
        path="/search/search"
        title="Search"
        exact
        element={
          <SearchRoutesWrap title="Search" {...props}>
            <FullSearch {...props} />
          </SearchRoutesWrap>
        }
      />
      <Route
        path="/search/search/product/:id"
        element={
          <SearchRoutesWrap title="Product" {...props}>
            <ProductFullPage {...props} catalogue />
          </SearchRoutesWrap>
        }
      />
      <Route
        path="/search/search/matching"
        element={
          <SearchRoutesWrap title="Matching Product" {...props}>
            <ProductsMatching {...props} catalogue />
          </SearchRoutesWrap>
        }
      />
      {/* </Route>
             :
            <Route path="/" title="Dashboard" exact element={<NoRoutesWrap title="Dashboard" ><_Landing_old {...props} /></NoRoutesWrap>} />
             } */}

      {/* buyers catalogue routes */}

      {getAccessID(user?.user?.user_permissions?.buyers?.access) === 1 ? (
        <Route>
          <Route
            path="/buyers/catalogue"
            element={
              <BuyerRoutesWrap title="Catalogue" {...props}>
                <BuyerCatalogueLayout {...props} />
              </BuyerRoutesWrap>
            }
          />
          <Route
            path="/buyers/catalogue/product/:id"
            element={
              <BuyerRoutesWrap title="Catalogue" {...props}>
                <ProductGroupFullPage {...props} />
              </BuyerRoutesWrap>
            }
          />
          <Route
            path="/buyers/catalogue/search"
            element={
              <BuyerRoutesWrap title="Catalogue" {...props}>
                <BuyerSearchLayout {...props} />
              </BuyerRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.buyers?.access) === 2 ? (
        <Route path="/buyers/catalogue" element={<Preview />} />
      ) : (
        <Route path="/buyers/catalogue" element={<NoAccess />} />
      )}

      {/* engineers catalogue routes */}
      {getAccessID(user?.user?.user_permissions?.engineers?.access) === 1 ? (
        <Route>
          <Route
            path="/engineers/catalogue"
            element={
              <ShopRoutesWrap title="Catalogue" {...props}>
                <CatalogueLayout {...props} catalogue />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="/engineers/catalogue/matching"
            element={
              <ShopRoutesWrap title="Catalogue" {...props}>
                <MatchingProductsLayout {...props} catalogue />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="/engineers/catalogue/product/:id"
            element={
              <ShopRoutesWrap title="Catalogue" {...props}>
                <ProductFullPage {...props} catalogue />
              </ShopRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.engineers?.access) === 2 ? (
        <Route path="/engineers/catalogue" element={<Preview />} />
      ) : (
        <Route path="/engineers/catalogue" element={<NoAccess />} />
      )}

      {/* vending routes */}
      {getAccessID(user?.user?.user_permissions?.vend?.access) === 1 ? (
        <Route>
          {/* <Route path="/vend/dashboard" element={<IframePage area="vend" {...props} />} /> */}
          <Route
            path="/vend/dashboard"
            element={
              <NoRoutesWrap title="Vend">
                <Vend title="Vend" {...props} />
              </NoRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.vend?.access) === 2 ? (
        <Route path="/vend/dashboard" element={<Preview />} />
      ) : (
        <Route path="/vend/dashboard" element={<NoAccess />} />
      )}

      {/* coolantcare routes */}
      {getAccessID(user?.user?.user_permissions?.coolantcare?.access) === 1 ? (
        <Route>
          <Route
            path="/coolantcare"
            element={
              <CoolantRoutesWrap title="Coolant - Dashboard" {...props}>
                {mobile ? (
                  <MobileLanding {...props} />
                ) : (
                  <Dashboard {...props} />
                )}
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/machines"
            element={
              <CoolantRoutesWrap title="Coolant - Machines" {...props}>
                <Machines {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/machine/:id"
            element={
              <CoolantRoutesWrap title="Coolant - Machine" {...props}>
                <Machine {...props} />
              </CoolantRoutesWrap>
            }
          />
          {/* <Route path="/coolantcare/additives" element={<CoolantRoutesWrap title="Coolant - Additives" {...props} ><Additives {...props} /></CoolantRoutesWrap>} />
                    <Route path="/coolantcare/coolants" element={<CoolantRoutesWrap title="Coolant - Coolants" {...props} ><Coolants {...props} /></CoolantRoutesWrap>} />*/}
          <Route
            path="/coolantcare/products"
            element={
              <CoolantRoutesWrap title="Coolant - Products" {...props}>
                <Products {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/reports"
            element={
              <CoolantRoutesWrap title="Coolant - Reports" {...props}>
                <Reports {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/companies"
            element={
              <CoolantRoutesWrap title="Coolant - Companies" {...props}>
                <Companies {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/machine/entry"
            element={
              <CoolantRoutesWrap title="Coolant - Entry" {...props}>
                <FullEntry {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/diagnostic"
            element={
              <CoolantRoutesWrap title="Coolant - Diagnostic" {...props}>
                <Diagnostic {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/qr-code"
            element={
              <CoolantRoutesWrap title="Coolant - Scanner" {...props}>
                <Scanner {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/service"
            element={
              <CoolantRoutesWrap title="Coolant - Service" {...props}>
                <Service {...props} />
              </CoolantRoutesWrap>
            }
          />
          <Route
            path="/coolantcare/attributes"
            element={
              <CoolantRoutesWrap title="Coolant - Attributes" {...props}>
                <CompanyAttributes {...props} />
              </CoolantRoutesWrap>
            }
          />
          {/* <Route path="/coolantcare/maintenance" element={<CoolantRoutesWrap title="Coolant - Maintenance" {...props} ><Maintenance {...props} /></CoolantRoutesWrap>} /> */}
          <Route
            path="/coolantcare/display"
            element={
              <CoolantRoutesWrap title="Coolant - Display" {...props}>
                <DisplayView {...props} />
              </CoolantRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.coolantcare?.access) ===
        2 ? (
        <Route path="coolantcare" element={<Preview />} />
      ) : (
        <Route path="coolantcare" element={<NoAccess />} />
      )}

      {/* parametric routes */}
      {getAccessID(user?.user?.user_permissions?.parametric?.access) === 1 ? (
        <Route>
          <Route
            path="parametric/search"
            element={
              <ShopRoutesWrap title="Parametric" {...props}>
                <Parametric {...props} parametric />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="parametric/search/results"
            element={
              <ShopRoutesWrap title="Parametric" {...props}>
                <ParametricProductsLayout {...props} parametric />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="parametric/search/matching"
            element={
              <ShopRoutesWrap title="Parametric" {...props}>
                <MatchingProductsLayout {...props} parametric />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="parametric/search/product/:id"
            element={
              <ShopRoutesWrap title="Parametric" {...props}>
                <CIMProductFullPage {...props} parametric />
              </ShopRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.parametric?.access) ===
        2 ? (
        <Route path="parametric/search" element={<Preview />} />
      ) : (
        <Route path="parametric/search" element={<NoAccess />} />
      )}

      {/* application routes */}
      {getAccessID(user?.user?.user_permissions?.application?.access) === 1 ? (
        <Route>
          <Route
            path="/application/search"
            exact
            element={
              <ShopRoutesWrap title="Application" {...props}>
                <ApplicationSearch {...props} application />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="/application/search/results"
            element={
              <ShopRoutesWrap title="Application" {...props}>
                <ParametricProductsLayout {...props} application />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="/application/search/product/:id"
            element={
              <ShopRoutesWrap title="Application" {...props}>
                <CIMProductFullPage {...props} application />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="/application/search/matching"
            element={
              <ShopRoutesWrap title="Application" {...props}>
                <MatchingProductsLayout {...props} application />
              </ShopRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.application?.access) ===
        2 ? (
        <Route path="/application/search" element={<Preview />} />
      ) : (
        <Route path="/application/search" element={<NoAccess />} />
      )}

      {/* solutions routes */}
      {getAccessID(user?.user?.user_permissions?.solution?.access) === 1 ? (
        <Route>
          <Route
            path="/solution/new"
            element={
              <SolutionsRoutesWrap title="Solutions - New" {...props}>
                <SolutionsNew {...props} />
              </SolutionsRoutesWrap>
            }
          />
          <Route
            path="/solution/dashboard"
            element={
              <SolutionsRoutesWrap title="Solutions - Dashboard" {...props}>
                <Solutions {...props} />
              </SolutionsRoutesWrap>
            }
          />
          <Route
            path="/solution/view"
            element={
              <SolutionsRoutesWrap title="Solutions - View" {...props}>
                <SolutionsView {...props} />
              </SolutionsRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.solution?.access) === 2 ? (
        <Route path="/solution/dashboard" element={<Preview />} />
      ) : (
        <Route path="/solution/dashboard" element={<NoAccess />} />
      )}

      {/* design routes */}
      {getAccessID(user?.user?.user_permissions?.design?.access) === 1 ? (
        <Route>
          <Route
            path="/design/new"
            element={
              <DesignRoutesWrap title="Design - New" {...props}>
                <DesignNew {...props} />
              </DesignRoutesWrap>
            }
          />
          <Route
            path="/design/dashboard"
            element={
              <DesignRoutesWrap title="Design - Dashboard" {...props}>
                <Design {...props} />
              </DesignRoutesWrap>
            }
          />
          <Route
            path="/design/view"
            element={
              <DesignRoutesWrap title="Design - View" {...props}>
                <DesignView {...props} />
              </DesignRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.design?.access) === 2 ? (
        <Route path="/design/dashboard" element={<Preview />} />
      ) : (
        <Route path="/design/dashboard" element={<NoAccess />} />
      )}

      {/* cadcam routes */}
      {getAccessID(user?.user?.user_permissions?.cadcam?.access) === 1 ? (
        <Route>
          <Route
            path="/cadcam/search"
            element={
              <ShopRoutesWrap title="CadCam - Search" {...props}>
                <CadCam {...props} />
              </ShopRoutesWrap>
            }
          />
          <Route
            path="/cadcam/search/product/:id"
            element={
              <ShopRoutesWrap title="CadCam - Search" {...props}>
                <CIMProductFullPage {...props} application />
              </ShopRoutesWrap>
            }
          />
          {/* <Route path="/cadcam/search/matching" element={<ShopRoutesWrap {...props}><Matching {...props} application /></ShopRoutesWrap>} />
                     <Route path="/cadcam/downloads" element={<ShopRoutesWrap {...props}><CadCam {...props} /></ShopRoutesWrap>} /> */}
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.cadcam?.access) === 2 ? (
        <Route path="/cadcam/search" element={<Preview />} />
      ) : (
        <Route path="/cadcam/search" element={<NoAccess />} />
      )}

      {/* cnc routes */}
      {getAccessID(user?.user?.user_permissions?.cnc?.access) === 1 ? (
        <Route path="/cnc" element={<ComingSoon {...props} />} />
      ) : getAccessID(user?.user?.user_permissions?.cnc?.access) === 2 ? (
        <Route path="/cnc" element={<Preview />} />
      ) : (
        <Route path="/cnc" element={<NoAccess />} />
      )}

      {/* kpi routes */}
      {getAccessID(user?.user?.user_permissions?.kpi?.access) === 1 ? (
        <Route>
          <Route
            path="/kpi/dashboard"
            element={
              <KPIRoutesWrap title="KPI - Dashboard" {...props}>
                <KPIDashboard {...props} />
              </KPIRoutesWrap>
            }
          />
          {user?.user?.user_permissions?.administrator && (
            <Route>
              <Route
                path="/kpi/edit"
                element={
                  <KPIRoutesWrap title="KPI - Dashboard" {...props}>
                    <KPI {...props} />
                  </KPIRoutesWrap>
                }
              />
            </Route>
          )}
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.kpi?.access) === 2 ? (
        <Route path="/kpi/dashboard" element={<Preview />} />
      ) : (
        <Route path="/kpi/dashboard" element={<NoAccess />} />
      )}

      {/* cvr routes */}
      {/* {getAccessID(user?.user?.user_permissions?.cvr?.access) === 1 ? ( */}
      {user?.user?.user_permissions?.administrator ? (
        <Route>
          <Route
            path="/cvr/dashboard"
            element={
              <CVRRoutesWrap title="CVR - Dashboard" {...props}>
                <CVRDashboard {...props} />
              </CVRRoutesWrap>
            }
          />
          {user?.user?.user_permissions?.administrator && (
            <Route>
              <Route
                path="/cvr/edit"
                element={
                  <CVRRoutesWrap title="CVR - Dashboard" {...props}>
                    <CVR {...props} />
                  </CVRRoutesWrap>
                }
              />
            </Route>
          )}
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.cvr?.access) === 2 ? (
        <Route path="/cvr/dashboard" element={<Preview />} />
      ) : (
        <Route path="/cvr/dashboard" element={<NoAccess />} />
      )}

      {/* fixtures routes */}
      {getAccessID(user?.user?.user_permissions?.fixtures?.access) === 1 ? (
        <Route>
          <Route
            path="/fixtures/new"
            element={
              <FixturesRoutesWrap title="Fixtures - New" {...props}>
                <FixturesNew {...props} />
              </FixturesRoutesWrap>
            }
          />
          <Route
            path="/fixtures/dashboard"
            element={
              <FixturesRoutesWrap title="Fixtures - Dashboard" {...props}>
                <Fixtures {...props} />
              </FixturesRoutesWrap>
            }
          />
          <Route
            path="/fixtures/view"
            element={
              <FixturesRoutesWrap title="Fixtures - View" {...props}>
                <FixturesView {...props} />
              </FixturesRoutesWrap>
            }
          />
        </Route>
      ) : getAccessID(user?.user?.user_permissions?.solution?.access) === 2 ? (
        <Route path="/fixtures/dashboard" element={<Preview />} />
      ) : (
        <Route path="/fixtures/dashboard" element={<NoAccess />} />
      )}

      {/* promotions routes */}
      {getAccessID(user?.user?.user_permissions?.promotions?.access) === 1 ? (
        <Route path="/promotions" element={<ComingSoon {...props} />} />
      ) : getAccessID(user?.user?.user_permissions?.promotions?.access) ===
        2 ? (
        <Route path="/promotions" element={<Preview />} />
      ) : (
        <Route path="/promotions" element={<NoAccess />} />
      )}

      {/* promotions routes */}
      {getAccessID(user?.user?.user_permissions?.resources?.access) === 1 ? (
        <Route
          path="/resources"
          element={
            <ResourceRoutesWrap title="Resources" {...props}>
              <Resources {...props} />
            </ResourceRoutesWrap>
          }
        />
      ) : getAccessID(user?.user?.user_permissions?.resources?.access) === 2 ? (
        <Route path="/resources" element={<Preview />} />
      ) : (
        <Route path="/resources" element={<NoAccess />} />
      )}

      <Route
        path="/settings/account"
        element={
          <AdminRoutesWrap title="Settings - Account" {...props}>
            <Account {...props} />
          </AdminRoutesWrap>
        }
      />
      <Route
        path="/settings/company"
        element={
          <AdminRoutesWrap title="Settings - Company" {...props}>
            <Settings {...props} />
          </AdminRoutesWrap>
        }
      />
      <Route
        path="/settings/favourites"
        element={
          <AdminRoutesWrap title="Settings - Favourites" {...props}>
            <Favourites {...props} />
          </AdminRoutesWrap>
        }
      />

      <Route
        path="/settings/chat"
        element={
          <AdminRoutesWrap title="settings - Chat" {...props}>
            <Chat {...props} />
          </AdminRoutesWrap>
        }
      />

      {/* Admin routes */}
      {user?.user?.user_permissions?.administrator && (
        <Route>
          <Route
            path="/settings/users"
            element={
              <AdminRoutesWrap title="Settings - Users" {...props}>
                <UserSettings {...props} />
              </AdminRoutesWrap>
            }
          />
          {/* <Route path="/settings/users/add" element={<AdminRoutesWrap title="Settings - Users" {...props}><AddUserFullPage {...props} /></AdminRoutesWrap>} /> */}
          <Route
            path="/settings/app"
            element={
              <AdminRoutesWrap title="Settings - Application" {...props}>
                <ApplicationSettings {...props} />
              </AdminRoutesWrap>
            }
          />
          {/* <Route path="/settings/solution" element={<AdminRoutesWrap title="Settings - Application" {...props}><SolutionSettings {...props} /></AdminRoutesWrap>} /> */}
        </Route>
      )}

      {/* Coming soon */}
      <Route path="/:area" element={<ComingSoon {...props} />} />

      {/* 404 */}
      <Route element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
