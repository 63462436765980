import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const CVRCard = ({ title, value, description }) => {
  return (
    <Card>
      <CardHeader
        disableTypography
        sx={{
          background: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
          fontSize: "1em",
        }}
        title={title}
      />
      <CardContent sx={{ display: "flex", alignContent: "flex-start" }}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{ position: "relative", zIndex: 1 }}
        >
          <Grid item xs={12} sx={{ position: "relative", zIndex: 1 }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontSize: "4em", textAlign: "center" }}
            >
              {value}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ position: "relative", zIndex: 1 }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", marginTop: "-25px" }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          alignItems: "flex-end",
          display: "flex",
          flex: "auto",
          padding: "1em",
          position: "relative",
          zIndex: 1,
        }}
      ></CardActions>
    </Card>
  );
};

export default CVRCard;
