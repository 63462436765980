import {
  Alert,
  Autocomplete,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import InputWrap from "../reusable/InputWrap";
import { LayoutContext } from "../store/LayoutContext";
import GlobalCompanySelect from "../reusable/GlobalCompanySelect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CVRForm = ({ groupOptions, areaOptions }) => {
  const { values, touched, errors, handleChange, setFieldValue } =
    useFormikContext();
  const { app } = useContext(LayoutContext);
  const [selectedArea, setSelectedArea] = useState();
  const [selectedType, setSelectedType] = useState();

  const typeOptions = [
    { id: "hour", name: "Hours Saved" },
    { id: "money", name: "Money Saved" },
  ];

  useEffect(() => {
    if (values.area && areaOptions) {
      let area = areaOptions.find((area) => area.name === values.area);
      setSelectedArea(area);
    }
    if (values.type && typeOptions) {
      let type = typeOptions.find((area) => area.id === values.type);
      setSelectedType(type);
    }
  }, [app, values, areaOptions]);

  //console.log(values)

  const handleDate = (date) => {
    setFieldValue("date", date);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <InputWrap name="Selected Company">
            <GlobalCompanySelect disabled fullWidth />
          </InputWrap>
        </Grid>
        <Grid item xs={12}>
          <InputWrap name="Entry Date">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                size="large"
                inputVariant="outlined"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={values.date}
                onChange={handleDate}
                disableFuture
                sx={{
                  width: "100%",
                  "& legend": { display: "none" },
                }}
              />
            </LocalizationProvider>
          </InputWrap>
        </Grid>
        <Grid item xs={12}>
          <InputWrap name="Entry Name">
            <TextField
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              sx={{ "& legend": { display: "none" } }}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={12}>
          <InputWrap name="Entry Description">
            <TextField
              multiline
              rows={5}
              id="description"
              name="description"
              value={values.description}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              sx={{ "& legend": { display: "none" } }}
            />
            {/* <DictaphoneWrap
              setFieldValue={setFieldValue}
              name="description"
              field={
                <TextField
                  multiline
                  rows={5}
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  sx={{ "& legend": { display: "none" } }}
                />
              }
            /> */}
          </InputWrap>
        </Grid>
        {/* <Grid item xs={12}>
          <InputWrap name="Associated Area">
            <Autocomplete
              disableClearable
              options={areaOptions}
              value={selectedArea || null}
              onChange={(e, value) => {
                setFieldValue("area", value.name);
                setSelectedArea(value);
              }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Area" />
              )}
              sx={{
                minWidth: 220,
                "& legend": { display: "none" },
              }}
            />
          </InputWrap>
        </Grid> */}
        {/* <Grid item xs={12}>
          <InputWrap name="Tags">
            <Autocomplete
              multiple
              options={groupOptions}
              value={values.tags || []}
              onChange={(e, value) => {
                setFieldValue("tags", value);
                //setTags(value)
              }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Add tags" />
              )}
            />
          </InputWrap>
        </Grid> */}
        <Grid item xs={6}>
          <InputWrap name="Saving Type">
            <Autocomplete
              options={typeOptions}
              value={selectedType || null}
              onChange={(e, value) => {
                if (value?.id) {
                  setFieldValue("type", value.id);
                  setSelectedType(value);
                } else {
                  setFieldValue("type", null);
                  setSelectedType(null);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Add CVR Saving" />
              )}
              sx={{
                "& legend": { display: "none" },
              }}
            />
          </InputWrap>
        </Grid>
        <Grid item xs={6}>
          <InputWrap name={selectedType?.name ? selectedType.name : "Value"}>
            <TextField
              disabled={!selectedType}
              id="type_value"
              name="type_value"
              type="number"
              value={values.type_value}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={touched.type_value && Boolean(errors.type_value)}
              helperText={touched.type_value && errors.type_value}
              sx={{ "& legend": { display: "none" } }}
            />
          </InputWrap>
        </Grid>
      </Grid>
    </>
  );
};

export default CVRForm;
