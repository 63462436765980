import {
    Box,
    Grid,
    TextField,
    Button
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useContext, useState } from 'react';
import Defaults from '../../Products/Coolants/Defaults';
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import GlobalDialog from '../../../reusable/GlobalDialog';
import { MachineContext } from '../../store/MachineContext';
import { CoolantContext } from '../../store/CoolantContext';
import { SAVE_SELECTED_COOLANT } from '../../store/CoolantReducers';
import { UPDATE_MACHINE_SETTINGS } from '../../store/MachineReducers';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import { useMutation, useQuery } from "@tanstack/react-query";
import DialogLoader from '../../../reusable/DialogLoader';
import config from '../../../config';
import axiosRequest from '../../../axios/axoisRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyContext } from '../../../store/CompanyContext';


const AddCoolant = ({ selectedCoolant, selectedMachine, hideButton }) => {

    const { user } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { dispatchSelectedCoolant } = useContext(CoolantContext);
    const [open, setOpen] = useState(false);
    const [coolant, setCoolant] = useState(selectedCoolant);
    const { updateActivityLog } = useActivityLog();

    //get coolants based on what compnay has access to
    const { data: coolants } = useQuery({
        queryKey: ['all coolants', selectedCompany?.company_id],
        queryFn: ({ signal }) => axiosRequest({
            signal: signal,
            gateway: config.coolantAPI.URL,
            endpoint: 'coolants',
            api_key: user.api_key,
            method: 'get',
            params: {
                type: 1, //coolants
                get_list: true,
                company_id: selectedCompany.company_id
            }
        }),
        enabled: !!selectedCompany?.company_id,
        retry: false
    })

    const addCoolantToMachine = useMutation({
        mutationFn: (body) => axiosRequest({
            gateway: config.coolantAPI.URL,
            endpoint: 'machines',
            api_key: user.api_key,
            method: 'patch',
            body: body
        }),
        onSuccess: (context, variables) => {

            console.log('variables', variables);

            if (variables.noCoolant) {

                dispatchSelectedMachine({
                    type: UPDATE_MACHINE_SETTINGS,
                    payload: {
                        coolant_id: -1,
                        machine_defaults: {
                            "factor": 0,
                            "op_con_max": 0,
                            "op_con_min": 0,
                            "ph_max": 0,
                            "ph_min": 0
                        }
                    }
                });

                dispatchSelectedMachine({
                    type: UPDATE_MACHINE_SETTINGS,
                    payload: {
                        last_coolant_change: Date.now()
                    }
                });

                updateActivityLog.mutate({
                    activity_details: {
                        area: "coolant",
                        machine_id: selectedMachine.machine_id,
                    },
                    activity_type: "no coolant"
                })

            } else {

                dispatchSelectedCoolant({
                    type: SAVE_SELECTED_COOLANT,
                    payload: coolant
                });

                dispatchSelectedMachine({
                    type: UPDATE_MACHINE_SETTINGS,
                    payload: {
                        coolant_id: coolant.product_id,
                        machine_defaults: {
                            "factor": coolant.product_defaults.defaults.factor,
                            "op_con_max": coolant.product_defaults.defaults.con_max,
                            "op_con_min": coolant.product_defaults.defaults.con_min,
                            "ph_max": coolant.product_defaults.defaults.ph_max,
                            "ph_min": coolant.product_defaults.defaults.ph_min
                        }
                    }
                });

                dispatchSelectedMachine({
                    type: UPDATE_MACHINE_SETTINGS,
                    payload: {
                        last_coolant_change: Date.now()
                    }
                });

                updateActivityLog.mutate({
                    activity_details: {
                        area: "coolant",
                        machine_id: selectedMachine.machine_id,
                        new_settings: {
                            "newCoolant": coolant.product_id,
                        }
                    },
                    activity_type: "coolant added"
                })
            }
        }
    })

    const handleSubmit = () => {
        addCoolantToMachine.mutate({
            edit: 'coolant',
            machine_id: selectedMachine.machine_id,
            coolant: coolant,
            newCoolant: coolant.product_id,
            resetDate: true,
            selectedDate: new Date(),
            resetMachineSettings: true
        })
    }

    const handleNoCoolantSubmit = () => {
        addCoolantToMachine.mutate({
            edit: 'coolant',
            machine_id: selectedMachine.machine_id,
            noCoolant: true,
            resetDate: true,
            selectedDate: new Date(),
            resetMachineSettings: true
        })
    }

    const closeReset = () => {
        setOpen(false);
    }

    return (
        <Grid container item spacing={2} alignItems='center' justifyContent='center'>
            <Grid item >
                <Button
                    id="edit"
                    variant="contained"
                    color="primary"
                    onClick={e => setOpen(true)}
                //startIcon={<FontAwesomeIcon icon={faPlus} />}
                >
                    {setLanguageText("Add Coolant")}
                </Button>
            </Grid>
            {/* {!hideButton &&
                <Grid item >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleNoCoolantSubmit}
                    //startIcon={<FontAwesomeIcon icon={faTimes} />}
                    >
                        {setLanguageText("No Coolant Needed")}
                    </Button>
                </Grid>
            } */}
            {open ?
                <GlobalDialog
                    open={open}
                    onClose={closeReset}
                    title="Add Coolant"
                    buttonClick={handleSubmit}
                    buttonTitle="Add Coolant"
                    successMessage="Coolant added successfully!"
                    fullWidth
                    maxWidth={"sm"}
                >
                    <Grid container item spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="coolant"
                                options={coolants || []}
                                loading={!coolants ? true : false}
                                fullWidth

                                getOptionLabel={(option) => option.product_name || ''}
                                isOptionEqualToValue={(option, value) => option.product_id === value.product_id}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.product_id}>
                                        {option.product_name}
                                    </Box>
                                )}
                                renderInput={params => (
                                    <TextField
                                        required
                                        {...params}
                                        label={setLanguageText("Select Coolant")}
                                        variant="outlined"
                                    />
                                )}
                                value={coolant || null}
                                onChange={(e, newValue) => setCoolant(newValue)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Defaults coolant={coolant} />
                        </Grid>
                    </Grid>
                    {addCoolantToMachine.isLoading || addCoolantToMachine.isSuccess || addCoolantToMachine.isError ?
                        <DialogLoader
                            isLoading={addCoolantToMachine.isLoading}
                            mutation={addCoolantToMachine}
                            loadingMessage="Adding Coolant..."
                            successMessage="Coolant Added"
                            fixed
                        />
                        : null}
                </GlobalDialog>
                : null}
        </Grid>
    );
};

export default AddCoolant;
