import React, { useMemo, useState, useReducer } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";

import {
  Alert,
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Filter from "../tables/Filter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isMobileOnly } from "react-device-detect";

const CVRTable = ({
  pagination,
  setPagination,
  dataQuery,
  setDateFrom,
  dateFrom,
  setDateTo,
  dateTo,
}) => {
  const defaultData = useMemo(() => [], []);

  const handleDateFrom = (date) => {
    console.log(date);
    setDateFrom(date);
  };

  const handleDateTo = (date) => {
    console.log(date);
    setDateTo(date);
  };

  const columns = useMemo(
    () => [
      {
        id: "id",
        accessorKey: "id",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => info.getValue(),
      },
      {
        header: "name",
        accessorKey: "name",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => info.getValue(),
      },
      {
        header: "description",
        accessorKey: "description",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => info.getValue(),
      },
      {
        header: "type",
        accessorKey: "type",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => info.getValue(),
      },
      {
        header: "type_value",
        accessorKey: "type_value",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => info.getValue(),
      },
    ],
    []
  );

  const table = useReactTable({
    data: dataQuery.data ?? defaultData,
    columns,
    // pageCount: dataQuery.data?.pageCount ?? -1, //you can now pass in `rowCount` instead of pageCount and `pageCount` will be calculated internally (new in v8.13.0)
    rowCount: dataQuery.data?.rowCount, // new in v8.13.0 - alternatively, just pass in `pageCount` directly
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true, //we're doing manual "server-side" pagination
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              size="medium"
              label={"Date From"}
              fullWidth={isMobileOnly}
              inputVariant="outlined"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={dateFrom}
              onChange={handleDateFrom}
              disableFuture
              sx={{ marginRight: 2 }}
            />
            <DatePicker
              size="medium"
              label={"Date To"}
              fullWidth={isMobileOnly}
              inputVariant="outlined"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={dateTo}
              onChange={handleDateTo}
              minDate={dateFrom}
              disableFuture
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <TablePagination
            rowsPerPageOptions={[1, 10, 50, 100, 200]}
            component="div"
            count={table.getFilteredRowModel().rows.length}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageIndex}
            onPageChange={(_, page) => {
              table.setPageIndex(page);
            }}
            onRowsPerPageChange={(e) => {
              const size = e.target.value ? Number(e.target.value) : 10;
              table.setPageSize(size);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table
              //size="small"
              sx={{ minWidth: 650, border: "1px solid #ccc" }}
              aria-label="simple table"
            >
              <TableHead>
                {table.getHeaderGroups().map((headerGroup, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: (theme) => theme.palette.table.header,
                    }}
                  >
                    {headerGroup.headers.map((header, i) => {
                      return (
                        <TableCell key={i} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  />
                                </div>
                              ) : null}
                            </>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {dataQuery.isFetching ? (
                  //create array from a number
                  new Array(pagination.pageSize).fill(0).map((_, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          cursor: "pointer",
                          borderBottom: "1px solid #ccc",
                          backgroundColor: (theme) => theme.palette.table.row,
                          "&:nth-of-type(odd)": {
                            backgroundColor: (theme) =>
                              theme.palette.table.hover,
                          },
                          //backgroundColor: theme => row.state.Enabled ? theme.palette.table.rows : theme.palette.table.disabled,
                          // hide last border
                          "&:last-child td, &:last-child th": {
                            borderBottom: 0,
                          },
                        }}
                      >
                        <TableCell>
                          <Skeleton height={30} />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={30} />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={30} />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={30} />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={30} />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={30} />
                        </TableCell>
                        <TableCell>
                          <Skeleton height={36} variant="rounded" />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : !dataQuery.isFetching && dataQuery.data?.length > 0 ? (
                  table.getRowModel().rows.map((row, i) => {
                    return (
                      <>
                        <TableRow
                          key={i}
                          sx={{
                            cursor: "pointer",
                            borderBottom: "1px solid #ccc",
                            backgroundColor: (theme) => theme.palette.table.row,
                            "&:nth-of-type(odd)": {
                              backgroundColor: (theme) =>
                                theme.palette.table.hover,
                            },

                            // hide last border
                            "&:last-child td, &:last-child th": {
                              borderBottom: 0,
                            },
                          }}
                        >
                          {row.getVisibleCells().map((cell, i) => {
                            return (
                              <TableCell key={i}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <Alert sx={{ width: "100%", margin: "1em" }} severity="info">
                    No orders found
                  </Alert>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CVRTable;
