import { Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import config from "../config";
import PageContent from "../coolantcare/PageContent";
import InnerHeaderWrap from "../layout/InnerHeaderWrap";
import { UserContext } from "../store/UserContext";
import axiosRequest from "../axios/axoisRequest";
import { CompanyContext } from "../store/CompanyContext";
import AddCVR from "./AddCVR";
import { LayoutContext } from "../store/LayoutContext";
import GlobalCompanySelect from "../reusable/GlobalCompanySelect";
import CVRTable from "./CVRTable";
import { useQuery } from "@tanstack/react-query";
import CVRCard from "./CVRCard";

export default function CVRDashboard() {
  const { user } = useContext(UserContext);
  const { app } = useContext(LayoutContext);
  const { selectedCompany } = useContext(CompanyContext);
  const [areaOptions, setAreaOptions] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  ); //from 1 month ago
  const [dateTo, setDateTo] = useState(new Date()); //today

  const groupOptions = [
    { id: 1, name: "Accounts" },
    { id: 2, name: "Operations" },
    { id: 3, name: "Engineer" },
  ];

  useEffect(() => {
    if (app.menu) {
      let areas = [];
      app.menu.forEach((item, i) => {
        if (!item.hidden) {
          areas.push({ value: i, name: item.buttonText });
        }
      });
      setAreaOptions(areas);
    }
  }, [app.menu]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const dataQuery = useQuery({
    queryKey: [
      "data",
      pagination.pageSize,
      pagination.pageIndex,
      selectedCompany.company_id,
      dateFrom,
      dateTo,
    ],
    queryFn: () => {
      return axiosRequest({
        method: "get",
        endpoint: "kpi",
        gateway: config.factoryAPI.URL,
        api_key: user.api_key,
        params: {
          company_id: selectedCompany.company_id,
          limit: pagination.pageSize,
          offset: pagination.pageSize * pagination.pageIndex,
          //change date time to 00:00:00
          date_from: new Date(
            dateFrom.getFullYear(),
            dateFrom.getMonth(),
            dateFrom.getDate()
          ).toISOString(),
          //change date to midnight on the current day
          date_to: new Date(
            dateTo.getFullYear(),
            dateTo.getMonth(),
            dateTo.getDate() + 1
          ).toISOString(),
        },
      });
    },
    enabled: !!selectedCompany?.company_id,
  });

  return (
    <>
      <InnerHeaderWrap>
        <Grid
          spacing={2}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            <GlobalCompanySelect addAll />
          </Grid>
          {user.user.user_permissions.administrator && (
            <Grid container xs justifyContent="flex-end">
              <Grid item>
                <AddCVR
                  refetchCVRs={dataQuery.refetch}
                  groupOptions={groupOptions}
                  areaOptions={areaOptions}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </InnerHeaderWrap>
      <PageContent>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={6}>
              <CVRCard title="Hours Saved" value={"20"} description="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CVRCard title="Money Saved" value={"£300"} description="" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <CVRTable
                pagination={pagination}
                setPagination={setPagination}
                dataQuery={dataQuery}
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}
