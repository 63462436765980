import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../store/CompanyContext";
import { LayoutContext } from "../store/LayoutContext";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { SELECTED_COMPANY } from "../store/CompanyReducers";
import setLanguageText from "../language/setLanguageText";
import { UserContext } from "../store/UserContext";
import config from "../config";
import { useMutation } from "react-query";
import axiosRequest from "../axios/axoisRequest";
import addAllOption from "../helpers/addAllOption";
import { isMobile } from "react-device-detect";

function GlobalCompanySelect({ addAll, fullWidth, large, disabled }) {
  const { companies, selectedCompany, dispatchSelectedCompany } =
    useContext(CompanyContext);
  const { language } = useContext(LayoutContext);
  const { user } = useContext(UserContext);
  const [selected, setSelected] = useState(null);

  //set seleceted
  useEffect(() => {
    if (selectedCompany && Object.values(selectedCompany).length) {
      if (selectedCompany.company_id === 0) {
        if (addAll) {
          setSelected(selectedCompany);
        } else {
          //drowpdown doesn't allowed "all companies"
        }
      } else {
        setSelected(selectedCompany);
      }
      //console.log(selectedCompany)
      //console.log('set from context')
    }
  }, [user, selectedCompany]);

  //gets companies full details
  const getCompanyDetails = useMutation(
    (company) => {
      //console.log(user)
      return axiosRequest({
        method: "get",
        endpoint: "companies",
        gateway: config.coolantAPI.URL,
        api_key: user.api_key,
        params: {
          id: company.company_id === 0 ? "zero" : company.company_id,
          // 0 company doesn't exist in the database , so fetch atrributes from another company
          company_name: company.company_id === 0 ? company.company_name : null,
        },
      });
    },
    {
      onSuccess: (data) => {
        dispatchSelectedCompany({
          type: SELECTED_COMPANY,
          payload: data[0],
        });
        window.sessionStorage.setItem("selectedCompany", data[0].company_id);
      },
    }
  );

  const selectCompany = (company) => {
    if (company) {
      getCompanyDetails.mutate(company);
    }
  };

  //set local options
  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    if (companies) {
      if (addAll) {
        setCompanyOptions(addAllOption(companies, language));
      } else {
        setCompanyOptions(companies);
      }
    }
  }, [addAll, companies]);

  return (
    <Autocomplete
      disabled={disabled}
      sx={{
        position: "relative",
        zIndex: 1,
        //height: large ? "100%" : "auto",
        height: large ? (isMobile ? "auto" : "70px") : "auto",
      }}
      disableClearable
      id="company"
      value={selected}
      options={companyOptions}
      loading={companyOptions ? false : true}
      getOptionLabel={(option) => {
        //console.log(option)
        let string = "";
        if (option.company_name) {
          string = option.company_name;
        }
        if (option?.company_erp && option?.company_erp?.AccountNumber) {
          string += ` - ${option.company_erp.AccountNumber}`;
        }
        return string;
      }}
      isOptionEqualToValue={(option, value) =>
        option.company_id === value.company_id
      }
      onChange={(e, newValue) => selectCompany(newValue)}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.company_id}>
            {option.company_name}
            {option?.company_erp && option?.company_erp?.AccountNumber
              ? ` - ${option.company_erp.AccountNumber}`
              : ""}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          //label={setLanguageText("Selected Company")}
          sx={{
            width: fullWidth ? "100%" : 300,
            position: "relative",
            zIndex: 1,
            "& input": {
              zIndex: 2,
              padding: large
                ? isMobile
                  ? "4px!important"
                  : "16.5px 14px!important"
                : "inherit",
            },
            "& .MuiInputBase-root": {
              //height: large ? "70px" : "auto",
              height: large ? (isMobile ? "auto" : "70px") : "auto",
            },
            "& legend": { display: "none" },
            "& fieldset": {
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#000" : "#fff",
            },
            "& .MuiAutocomplete-endAdornment ": {
              zIndex: 2,
            },
          }}
          placeholder={setLanguageText("Select Company")}
          //required
        />
      )}
    />
  );
}

export default GlobalCompanySelect;
