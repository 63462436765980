import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faListCheck } from "@fortawesome/pro-light-svg-icons";

const AttributeToggle = ({ attributes, setAttributes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [all, setAll] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ToggleButton
        color="primary"
        onClick={handleClick}
        size="small"
        sx={{ marginRight: "5px" }}
        value={open}
      >
        <FontAwesomeIcon
          icon={faListCheck}
          size="2x"
          style={{ cursor: "pointer" }}
        />
      </ToggleButton>

      {/* 
            <Button
                color='secondary'
                variant='contained'
                onClick={handleClick}
            >
                <FontAwesomeIcon
                    icon={faCog}
                    size="2x"
                    style={{ cursor: 'pointer', padding: '0 5px' }}
                />
            </Button> */}
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": "all" }}
                  checked={all}
                  onChange={(e, vaule) => {
                    if (!all) {
                      //set all attributes is checked
                      let newAttributes = attributes.map((item) => {
                        return { ...item, checked: true };
                      });
                      setAttributes(newAttributes);
                      setAll(!all);
                    } else {
                      //set all attributes is unchecked
                      let newAttributes = attributes.map((item) => {
                        return { ...item, checked: false };
                      });
                      setAttributes(newAttributes);
                      setAll(!all);
                    }
                  }}
                />
              </ListItemIcon>
              <ListItemText id="all" primary="Toggle All" />
            </ListItemButton>
          </ListItem>
          {attributes
            ? attributes.map((column, i) => {
                return (
                  <ListItem key={i} disablePadding>
                    <ListItemButton dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": column.id }}
                          checked={column.checked}
                          onChange={(e, value) => {
                            console.log(value);
                            //set attributes is checked
                            let newAttributes = attributes.map((item) => {
                              if (item.Ref === column.Ref) {
                                return { ...item, checked: value };
                              } else {
                                return item;
                              }
                            });
                            setAttributes(newAttributes);
                          }}
                        />
                      </ListItemIcon>

                      <ListItemText
                        id={column.id}
                        primary={
                          <Typography fontSize={14}>
                            <Typography
                              component="span"
                              fontSize={14}
                              fontWeight={700}
                            >
                              {column.Ref ? `${column.Ref}` : null}:
                            </Typography>{" "}
                            {column.Name}{" "}
                            {column.Unit ? `(${column.Unit})` : null}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
            : null}
        </List>
      </Menu>
    </div>
  );
};
export default AttributeToggle;
