export default function createSingleProduct({ product, brands }) {
  return {
    product_id: product?._source?.product_id
      ? product?._source?.product_id
      : null,
    product_cim_id:
      product?._source?.product_cim_id &&
      product?._source?.product_cim_id !== " "
        ? product?._source?.product_cim_id
        : null,
    product_number: product?._source?.product_code
      ? product?._source?.product_code
      : null,
    product_description: product?._source?.description
      ? product?._source?.description
      : null,
    product_supplier_number: product?._source?.supplier_number
      ? product?._source?.supplier_number
      : null,
    product_supplier: product?._source?.supplier
      ? product?._source?.supplier
      : null,
    product_supplier_name: product?._source?.supplier_name
      ? product?._source?.supplier_name
      : null,
    product_manufacturer: product?._source?.manufacturer
      ? product?._source?.manufacturer
      : null,
    product_rrp: product?._source?.price ? product?._source?.price : null,
    product_cim_code:
      product?._source?.cim_code && product?._source?.cim_code !== " "
        ? product?._source?.cim_code
        : null,
    product_ezbase_code:
      product?._source?.ezbase_code && product?._source?.ezbase_code !== " "
        ? product?._source?.ezbase_code
        : null,
    product_packet_size: product?._source?.packet_size
      ? product?._source?.packet_size
      : null,

    //added from the new enhanced products
    product_main_photo: product?._source?.main_photo
      ? product?._source?.main_photo
      : null,

    //"product_stock": product?._source?.stock,
  };
}
