import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import setLanguageText from "../../../language/setLanguageText";
import { buttonStyles } from "../../styles/buttonStyles";

const Defaults = ({ coolant, noHeader }) => {
  const buttonStyle = buttonStyles();

  return (
    <Grid container>
      <Grid item container spacing={1}>
        {noHeader ? null : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              <strong>{setLanguageText("Product Defaults")}:</strong>{" "}
              {coolant?.product_name}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <Paper className={`${buttonStyle.box}`} variant="outlined">
            <Typography variant="body1" align="center">
              <strong>{setLanguageText("PH Min")}: </strong>
              {coolant?.product_defaults?.defaults?.ph_min}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={`${buttonStyle.box}`} variant="outlined">
            <Typography variant="body1" align="center">
              <strong>{setLanguageText("PH Max")}: </strong>
              {coolant?.product_defaults?.defaults?.ph_max}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={`${buttonStyle.box}`} variant="outlined">
            <Typography variant="body1" align="center">
              <strong>{setLanguageText("Conc Min")}: </strong>
              {coolant?.product_defaults?.defaults?.con_min}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={`${buttonStyle.box}`} variant="outlined">
            <Typography variant="body1" align="center">
              <strong>{setLanguageText("Conc Max")}: </strong>
              {coolant?.product_defaults?.defaults?.con_max}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={`${buttonStyle.box}`} variant="outlined">
            <Typography variant="body1" align="center">
              <strong>{setLanguageText("Factor")}: </strong>
              {coolant?.product_defaults?.defaults?.factor}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Defaults;
