import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import config from "../../config";
import axiosRequest from "../../axios/axoisRequest";
import { UserContext } from "../../store/UserContext"
import { Grid, Typography, Card, CardContent, Checkbox, FormControlLabel, Button, AccordionSummary, Accordion, AccordionDetails, useMediaQuery, Alert } from "@mui/material";
import Image from "../reusable/Image";
import FormFields from "./FormFields";
import Overlay from "./fields/Overlay";
import { Box } from "@mui/system";
import Materials from "./Materials";
import { CLEAR_APPLICATION_FILTERS } from "../../store/SelectedReducers";
import { SelectedContext } from "../../store/SelectedContext";
import { ProductsContext } from "../../store/ProductsContext";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckboxBoolean from "./fields/CheckboxBoolean";
import createUrl from "../libs/createUrl";
import { useNavigate } from "react-router-dom";
import { CategoryContext } from "../../store/CategoryContext";
import { LayoutContext } from "../../store/LayoutContext";
import { UPDATE_PRODUCT_COUNT } from "../../store/ProductsReducers";
import { UPDATE_LAYOUT } from "../../store/LayoutReducers";
import { CompanyContext } from "../../store/CompanyContext";
import useSearchCount from "../../hooks/useSearchCount";

const ApplicationForm = ({ form, loading }) => {

    const navigator = useNavigate();
    const { updateSearchCount } = useSearchCount();
    const imageRef = useRef();
    const { user } = useContext(UserContext)
    const { selectedCompany } = useContext(CompanyContext)
    const { app, dispatchLayout } = useContext(LayoutContext)
    const { selected, dispatchSelected } = useContext(SelectedContext)
    const { products, dispatchProducts } = useContext(ProductsContext)
    const { categories } = useContext(CategoryContext);
    const [entries, setEntries] = useState();
    const [focus, setFocus] = useState();
    const [value, setValue] = useState();
    const [showMaterial, setShowMaterial] = useState();
    const [checked, setChecked] = useState(false);
    const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [noOptions, setNoOptions] = useState(false)

    //--------------------------- API CALLS  ------------------------------//

    const { data, error, isFetching, isFetched, } = useQuery(['application', form],
        ({ signal, queryKey }) => axiosRequest({
            signal: signal,
            gateway: config.shopAPI.URL,
            endpoint: 'applicationsearchrow/form',
            api_key: user.api_key,
            method: 'get',
            params: {
                toolClass: form
            }
        }), {
        enabled: !!user.api_key && !!form,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    const { data: result_data, isFetching: result_isFetching } = useQuery(['search', categories.currentCat.ID, selected.parametricFilters, selected.resultFilters],
        ({ signal }) => {
            //remove all not needed parameters
            //create copy of filters and remove reduntant one from API call
            //const search = parametricFilters.map(a => ({ ...a }));
            let search = structuredClone(selected.parametricFilters);
            //const filters = resultFilters.map(a => ({ ...a }));
            let filters = structuredClone(selected.resultFilters);


            //check if compnay has allowed companies in options
            if (!selectedCompany?.company_supplier_order) {
                setNoOptions(true)
                return 0
            } else {
                setNoOptions(false)
            }

            //console.log(selectedCompany?.company_supplier_order)
            //if there is no company filter, add it
            if (!filters.find(field => field.Field === "COMPC") && selectedCompany?.company_supplier_order) {
                filters.push({ "Field": "COMPC", "Values": selectedCompany.company_supplier_order, "DIN": "J3" })
                //[{"Field":"COMPC","Values":["Aura","BK"],"DIN":"J3"}]
            }

            //console.log(filters)
            search.map(filter => {
                delete filter.Name
                delete filter.ValueNames
                return filter
            })
            //console.log(filters)
            return axiosRequest({
                signal: signal,
                gateway: config.shopAPI.URL,
                endpoint: 'categories/parametric/results',
                api_key: user.api_key,
                method: 'get',
                params: {
                    category: categories.currentCat.ID,
                    //category: 'bnn',
                    search: JSON.stringify(search),
                    filters: JSON.stringify(filters),
                }
            })
        }, {
        //enabled: !!categories.currentCat.ID && !!selected.parametricFilters,
        enabled: !!selected.parametricFilters,
        notifyOnChangeProps: "tracked", //fecthes evertime the querykeys update
        retry: 1
    })

    useEffect(() => {
        //console.log(result_data)
        if (!result_data?.errorMessage) {
            //setCount(result_data)
            dispatchProducts({
                type: UPDATE_PRODUCT_COUNT,
                payload: {
                    count: result_data,
                }
            });
        } else {
            dispatchProducts({
                type: UPDATE_PRODUCT_COUNT,
                payload: {
                    count: -1,
                }
            });
        }
    }, [result_data])

    useEffect(() => {
        if (data) {
            setEntries(data.Entries)
            setShowMaterial(data.MaterialSearch)
            // reset focus
            setFocus();
            setValue()
            //console.log(data)
            //remove any lloacl stored filters
            //sessionStorage.removeItem("parametricFilters");
            window.scrollTo({
                top: imageRef.current.offsetTop - 160, //- toolbar
                left: 0,
                behavior: "smooth"
            });
            // dispatchSelected({
            //     type: CLEAR_APPLICATION_FILTERS
            // });
        }
    }, [data])

    //set focus on fields
    const focusField = (fieldAbr) => {
        //console.log(fieldAbr)
        setFocus(fieldAbr)
    }
    //update field with input value
    const updateValue = (value) => {
        //console.log(value)
        setValue(value)
    }

    const gotoProducts = (e, selected) => {

        //update search count
        updateSearchCount.mutate('application')

        //console.log(selected)
        let urlParams = new URLSearchParams(window.location.search)
        let appClass = urlParams.get("class")
        let appForm = urlParams.get("form")
        let appApp = urlParams.get("app")
        e.preventDefault();

        //reset page to 1
        dispatchLayout({
            type: UPDATE_LAYOUT,
            payload: {
                page: 1,
            }
        });

        let url = createUrl(`/${app.selectedArea}/search/results`, selected, categories, appClass, appForm, appApp);
        navigator(url);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    const checkboxes = [{
        Field: "Generate3DPossible",
        Name: "Generated 3D graphic",
        Position: 2,
        Ref: "Has3D",
        Type: "check",
        Unit: "",
        Values: []
    }, {
        Field: "HasUserManufStp",
        Name: "Manufacturer's 3D graphic",
        Position: 2,
        Ref: "STP",
        Type: "check",
        Unit: "",
        Values: []
    }]

    return (

        <>
            <Grid item xs={12}>
                <Card
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.paper,
                        display: 'flex',
                        justiyContent: 'space-between',
                        flexDirection: 'column',
                        width: '100%',
                        margin: '0 auto',
                        padding: '1em'
                    }}
                >
                    <CardContent>
                        <Grid item container spacing={4}>
                            <Grid item textAlign="center" xs={12}>
                                <Typography variant="h5">Assistant Machining operation ({data?.Label})</Typography>
                                <Typography variant="body2" >Please specify your search by using the interactive graphical feature.</Typography>
                            </Grid>
                            <Grid item container xs={12} md={6} spacing={2} alignContent="start">
                                {(!isFetching && entries) && (entries).map((field, i) =>
                                    <FormFields field={field} index={i} key={i} focus={focus} focusField={focusField} updateValue={updateValue} />
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} ref={imageRef} sx={{ minHeight: mobile ? 'auto' : 550 }}>
                                <Box sx={{ position: 'relative', height: mobile ? 'auto' : 500, width: mobile ? '100%' : 500 }}>
                                    <Image isFetching={isFetching} src={data?.Image} alt={data?.Label} height={mobile ? 'auto' : 500} ShowIcon={false} sx={{
                                        padding: '0',
                                        background: '#fff'
                                        // background: (theme) => theme.palette.background.paper
                                    }} />
                                    {(!mobile && !isFetching && entries) && (entries).map((field, i) =>
                                        <Overlay field={field} key={i} focus={focus} focusField={focusField} value={value} />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            {showMaterial ?
                <Grid item xs={12}>
                    <Card
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.paper,
                            display: 'flex',
                            justiyContent: 'space-between',
                            flexDirection: 'column',
                            width: '100%',
                            margin: '0 auto',
                            padding: '1em'
                        }}
                    >
                        <CardContent>
                            <Grid item container spacing={4}>
                                <Grid item textAlign="center" xs={12}>
                                    <Typography variant="h5">Material selection</Typography>
                                    <Typography variant="body2">Please select the required material by either choosing the material group or corresponding classification.</Typography>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={e => setChecked(current => !current)} />}
                                        label="Select material group"
                                    />
                                </Grid>
                                <Grid item textAlign="center" xs={12}>
                                    <Materials checked={checked} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                :
                null
            }
            <Grid item xs={12}>
                <Card
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.paper,
                        display: 'flex',
                        justiyContent: 'space-between',
                        flexDirection: 'column',
                        width: '100%',
                        margin: '0 auto',
                        padding: '1em'
                    }}
                >
                    <CardContent >
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                                        aria-controls="graphics-options"
                                        id="graphics-options"
                                        sx={{ background: (theme) => theme.palette.background.default, width: '100%', flexDirection: 'row-reverse' }}
                                    >
                                        <Typography sx={{ color: 'text.primary', paddingLeft: 2 }}>Graphics</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {checkboxes &&
                                            checkboxes.map((field, i) =>
                                                <CheckboxBoolean key={i} field={field} updateValue={updateValue} />
                                            )
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {/* <Grid item xs={12} textAlign="center" >
                                <Button
                                    //fullWidth
                                    variant="contained"
                                    onClick={(e) => gotoProducts(e, selected)}
                                    size="large"
                                    sx={{ fontSize: '1.125em' }}
                                //disabled={false}
                                >
                                    {result_isFetching ? 'Finding products...'
                                        : products.count === 0 ? `No products with thoses filters`
                                            :
                                            <span> Show <strong>{products.count ? products.count : '...'}</strong> Product{products.count > 1 && 's'}</span>
                                    }
                                </Button>
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Card
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 1000,
                    padding: '1em',
                    marginLeft: '1em',
                    marginTop: '1em',
                    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.25)',
                    //backgroundColor: (theme) => theme.palette.cards.footer
                    backgroundColor: (theme) => theme.palette.background.paper
                }}
            >
                <Grid
                    sx={{

                    }}
                    item xs={12} textAlign="center" >

                    {noOptions ?
                        <Grid container item xs={12} spacing={1} justifyContent="center">
                            <Grid item>
                                <Alert severity="info">No company options set, please contact support.</Alert>
                            </Grid>
                        </Grid>
                        :
                        <Button
                            //fullWidth
                            variant="contained"
                            onClick={(e) => gotoProducts(e, selected)}
                            size="large"
                            sx={{ fontSize: '1.125em' }}
                        //disabled={false}
                        >

                            {result_isFetching ? 'Finding products...'
                                : products.count === 0 ? `No products with thoses filters`
                                    :   products.count === -1 ? 'Error fetching products' :
                                    <span> Show <strong>{products.count ? products.count : '...'}</strong> Product{products.count > 1 && 's'}</span>
                            }
                        </Button>
                    }
                </Grid>
            </Card>
        </>
    );
}
export default ApplicationForm;
