import { useContext, useEffect, useState } from 'react';
import { BrandsContext } from '../store/BrandsContext';

export default function useEnhancedBrand({ setSelectedProduct, product }) {

    const { brands } = useContext(BrandsContext);

    //CIM product enhancement
    useEffect(() => {

        //console.log("product", product)
        //console.log("brands", brands.openSearchBrands)

        if (product && brands?.openSearchBrands) {

            let brand
            //cim product
            if (product?.product_supplier && brands?.openSearchBrands.length > 0 && (product?.product_cim_code !== "" && product?.product_cim_code)) {
                //console.log("cim")
                //brand = brands.openSearchBrands.find(brand => brand?.erp_code === product.product_supplier.toUpperCase() && brand?.supplier_code === product.product_cim_code && brand?.brand_name.toLowerCase() === product.product_manufacturer.toLowerCase())
                brand = brands.openSearchBrands.find(brand => brand?.brand_name.toLowerCase() === product.product_manufacturer.toLowerCase())
                //ezbase product
            } else if (product?.product_supplier && brands?.openSearchBrands.length > 0 && product.ezbase_code) {
                brand = brands.openSearchBrands.find(brand => brand?.erp_code === product.product_supplier.toUpperCase() && brand.supplier_name === product.ezbase_code && brand?.brand_name.toLowerCase() === product.product_manufacturer.toLowerCase())
                //console.log("ezbase")
                //no ezbase or cim code 
            } else if (product?.product_supplier && brands?.openSearchBrands.length) {
                //console.log("cis /act ")
                //brand = brands.openSearchBrands.find(brand => brand?.erp_code === product.product_supplier.toUpperCase() && brand?.brand_name.toLowerCase() === product.product_manufacturer.toLowerCase())
                brand = brands.openSearchBrands.find(brand => brand?.brand_name.toLowerCase() === product.product_manufacturer.toLowerCase())
            }

            if (setSelectedProduct) {
                setSelectedProduct(current => ({
                    ...current,
                    "product_brand_url": brand?.brand_logo ? brand?.brand_logo : "",
                    "product_external_delivery": brand?.external_delivery ? brand?.external_delivery : "",
                    "product_local_delivery": brand?.local_delivery ? brand?.local_delivery : "",
                }))
            }
        }

    }, [brands?.openSearchBrands, product])

    return { brand_isFetching: false }

};

