import { Button, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../store/UserContext";
import config from "../config";
import useActivityLog from "../users/activity/useActivityLog";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import axiosRequest from "../axios/axoisRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/pro-light-svg-icons";
import DialogLoader from "../reusable/DialogLoader";
import GlobalDialog from "../reusable/GlobalDialog";
import CVRForm from "./CVRForm";
import { CompanyContext } from "../store/CompanyContext";

export default function AddCVR({ refetchCVRs, groupOptions, areaOptions }) {
  const formRef = useRef();
  const { user } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const [open, setOpen] = useState(false);

  const resouceValidation = Yup.object({
    name: Yup.string("Enter the CVR name").required("CVR name is required"),
  });

  const newCVR = {
    name: "",
    area: "", //associated area
    company_id: "", //associated company
    description: "",
    type: "", //Hour saved or Money saved
    type_value: null,
    tags: [],
    documents: [],
    user: [],
    date: new Date(),
  };

  const { updateActivityLog } = useActivityLog();

  const mutation = useMutation(
    (values) => {
      return axiosRequest({
        endpoint: "kpi",
        method: "post",
        gateway: config.factoryAPI.URL,
        api_key: user.api_key,
        body: {
          new: true,
          ...values,
        },
      });
    },
    {
      onSuccess: async (response) => {
        if (!response.errorMessage) {
          updateActivityLog.mutate({
            activity_details: {
              area: "factory",
            },
            activity_type: "CVR added",
          });
        }
      },
    }
  );

  const closeModal = () => {
    setOpen(false);
    formRef.current.resetForm();
  };

  //save a kpi
  const submitResource = (values) => {
    //console.log(values)
    let object = {
      name: values.name,
      area: values.area,
      description: values.description,
      type: values.type,
      type_value: values.type_value,
      documents: values.documents,
      company_id: selectedCompany.company_id,
      user: user,
      date: values.date,
    };
    //console.log(object)
    mutation.mutate(object);
  };

  return (
    <>
      <Button
        //fullWidth
        variant="contained"
        component="label"
        onClick={() => setOpen(true)}
        startIcon={<FontAwesomeIcon icon={faBook} />}
      >
        Add New CVR
      </Button>

      <Formik
        innerRef={(current) => (formRef.current = current)} //access methods
        initialValues={newCVR}
        validationSchema={resouceValidation}
        onSubmit={submitResource}
      >
        {({ handleSubmit, values }) => {
          //console.log(values)
          return (
            <GlobalDialog
              open={open}
              onClose={closeModal}
              title={"Add CVR Entry"}
              buttonTitle={"Add CVR Entry"}
              buttonClick={handleSubmit}
              //disabled={true}
            >
              <CVRForm groupOptions={groupOptions} areaOptions={areaOptions} />
              {mutation.isLoading || mutation.isSuccess || mutation.isError ? (
                <DialogLoader
                  isLoading={mutation.isLoading}
                  mutation={mutation}
                  loadingMessage="Adding CVR..."
                  successMessage="Add CVR"
                  closeDialog={() => {
                    closeModal();
                    refetchCVRs();
                  }}
                  fixed
                />
              ) : null}
            </GlobalDialog>
          );
        }}
      </Formik>
    </>
  );
}
