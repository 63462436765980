import React, { useContext, useEffect, useMemo } from "react";
import {
  Document,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
  usePDF,
} from "@react-pdf/renderer";
import { useTheme } from "@emotion/react";
import { LayoutContext } from "../store/LayoutContext";
import PDFHeader from "./PDFHeader";
import PDFFooter from "./PDFFooter";
import {
  faDolly,
  faTruck,
  faTags,
  faBadgePercent,
} from "@fortawesome/pro-light-svg-icons";
import PDFFontAwesome from "./PDFFontAwesome.tsx";
import "../pdfWorker";
import config from "../config.js";

const SingleProductPDF = ({
  orientation,
  product,
  setDownloadObject,
  brandImage,
  mainImage,
  techImage,
  otherImages,
  price,
}) => {
  const { theme: themeContext } = useContext(LayoutContext);
  const theme = useTheme();

  //get base url
  const baseUrl = window.location.origin;

  //console.log(pdf_data);
  const styles = StyleSheet.create({
    BODY: {
      //paddingTop: 10,
      // paddingBottom: 35,
      //paddingHorizontal: 10,
      //fontFamily: 'Helvetica'
    },
    HEADER: {
      padding: "16px",
      //borderRadius: '5px',
      ROW: {
        padding: "0 5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
    },
    TEXT: {
      fontSize: 10,
      lineHeight: 1.4,
      paddingBottom: "5px",
    },
    BOLDTEXT: {
      //fontFamily: 'Helvetica-Bold',
      fontWeight: "bold",
    },
    HEADERTEXT: {
      textAlign: "center",
      fontSize: 16,
      lineHeight: 1.3,
      color: theme.palette.primary.main,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    WRAPHEADER: {
      fontSize: 13,
      lineHeight: 1.3,
      position: "relative",
      //marginTop: '5px'
    },
    TITLE: {
      fontSize: 14,
      lineHeight: 1.3,
    },
    WRAP: {
      //padding: '12px 16px 16px 16px',
      padding: "16px",
      backgroundColor: "#fff",
      //border: '1px solid #CCCCCC',
      //borderRadius: '5px',
      marginTop: "10px",
    },
    TABLE: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderColor: "#E0E0E0",
      ROW: {
        margin: "auto",
        flexDirection: "row",
      },
      COL: {
        width: "50%",
        borderStyle: "solid",
        borderColor: "#E0E0E0",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      CELL: {
        padding: "5px 10px 0 10px",
      },
    },
  });

  const create_pdf_rows = ({ rows, styles }) => {
    // console.log(rows)

    return (
      <View>
        {rows.map((row, i) => {
          return (
            <View
              style={[
                styles.TABLE.ROW,
                { backgroundColor: i % 2 === 0 ? "#EFEFEF" : "#FFFFFF" },
              ]}
              wrap={false}
              key={i}
            >
              <View style={styles.TABLE.COL}>
                <View style={styles.TABLE.CELL}>
                  <Text style={[styles.TEXT, styles.BOLDTEXT]}>
                    {row.name} ({row.code})
                  </Text>
                </View>
              </View>
              <View style={styles.TABLE.COL}>
                <View style={styles.TABLE.CELL}>
                  <Text style={styles.TEXT}>
                    {row.value} {row.unit ? row.unit : null}{" "}
                    {row.info ? `- ${row.info}` : ""}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const create_images = ({ images }) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {images.map((image, i) => (
          <View style={{ width: "50%", height: "100px" }} key={i}>
            <Image
              src={image.url}
              style={{ width: "auto", height: "auto", objectFit: "contain" }}
            />
          </View>
        ))}
      </View>
    );
  };

  const create_speeds_feeds = ({ speeds_feeds }) => {
    return (
      <View>
        <Text style={[styles.TEXT, { fontSize: "12px", marginBottom: "10px" }]}>
          <Text
            style={[
              styles.TEXT,
              styles.BOLDTEXT,
              { fontSize: "14px", color: "#333" },
            ]}
          >
            {speeds_feeds?.title}
          </Text>
        </Text>

        <Text
          style={[
            styles.TEXT,
            styles.BOLDTEXT,
            { fontSize: "12px", color: "#333", marginBottom: "5px" },
          ]}
        >
          General Guidelines:
        </Text>
        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.general_guidelines.cutting_speed.label}:{" "}
            </Text>
            {speeds_feeds?.sections.general_guidelines.cutting_speed.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.general_guidelines.cutting_speed.formular})
          </Text>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.general_guidelines.feed_rate.label}:{" "}
            </Text>
            {speeds_feeds?.sections.general_guidelines.feed_rate.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.general_guidelines.feed_rate.formular})
          </Text>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.general_guidelines.depth_of_cut.label}:{" "}
            </Text>
            {speeds_feeds?.sections.general_guidelines.depth_of_cut.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.general_guidelines.depth_of_cut.formular})
          </Text>
        </View>

        <Text
          style={[
            styles.TEXT,
            styles.BOLDTEXT,
            { fontSize: "12px", color: "#333", marginBottom: "5px" },
          ]}
        >
          Roughing:
        </Text>
        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.roughing.cutting_speed.label}:{" "}
            </Text>
            {speeds_feeds?.sections.roughing.cutting_speed.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.roughing.cutting_speed.formular})
          </Text>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.roughing.feed_rate.label}:{" "}
            </Text>
            {speeds_feeds?.sections.roughing.feed_rate.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.roughing.feed_rate.formular})
          </Text>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.roughing.depth_of_cut.label}:{" "}
            </Text>
            {speeds_feeds?.sections.roughing.depth_of_cut.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.roughing.depth_of_cut.formular})
          </Text>
        </View>

        <Text
          style={[
            styles.TEXT,
            styles.BOLDTEXT,
            { fontSize: "12px", color: "#333", marginBottom: "5px" },
          ]}
        >
          Finishing:
        </Text>
        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.finishing.cutting_speed.label}:{" "}
            </Text>
            {speeds_feeds?.sections.finishing.cutting_speed.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.finishing.cutting_speed.formular})
          </Text>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.finishing.feed_rate.label}:{" "}
            </Text>
            {speeds_feeds?.sections.finishing.feed_rate.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.finishing.feed_rate.formular})
          </Text>
          <Text style={styles.TEXT}>
            <Text style={styles.BOLDTEXT}>
              {speeds_feeds?.sections.finishing.depth_of_cut.label}:{" "}
            </Text>
            {speeds_feeds?.sections.finishing.depth_of_cut.value}{" "}
            {speeds_feeds?.sections.general_guidelines.cutting_speed.units} (
            {speeds_feeds?.sections.finishing.depth_of_cut.formular})
          </Text>
        </View>

        <Text style={[styles.TEXT, { fontSize: "10px", marginBottom: "10px" }]}>
          <Text style={styles.BOLDTEXT}>Source: </Text>
          {speeds_feeds?.source}
        </Text>

        <Text
          style={[
            styles.TEXT,
            {
              border: "1px solid #ea9d62",
              borderRadius: "5px",
              backgroundColor: "#fdf5f1",
              margin: "10px 0",
              padding: "10px 10px 5px 10px",
            },
          ]}
        >
          The exact speed and feed values may vary depending on the material
          condition, machine rigidity, and specific operation. The speed and
          feed values supplied are a guide only based on the information
          submitted. Please consult your local{" "}
          {config.APPNAME === "cis" ? "CIS" : "ACT"} Technical Solutions
          Engineer for further technical support and speed and feed
          optimization.
        </Text>
      </View>
    );
  };

  const create_product_downloads = ({ downloads }) => {
    return (
      <View>
        {downloads.map((download, i) => {
          return (
            <View
              style={[
                styles.TABLE.ROW,
                { backgroundColor: i % 2 === 0 ? "#EFEFEF" : "#FFFFFF" },
              ]}
              wrap={false}
              key={i}
            >
              <View style={[styles.TABLE.COL, { width: "100%" }]}>
                <View style={styles.TABLE.CELL}>
                  <Text style={styles.TEXT}>
                    <Text style={[styles.TEXT, styles.BOLDTEXT]}>
                      {download.ExportBase}
                    </Text>{" "}
                    : {download.Type}.{download.FileExtension}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const document = useMemo(
    () => (
      <Document title={`Product Export : ${product?.product_name} `}>
        <Page
          orientation={`${orientation}`}
          style={[
            styles.BODY,
            {
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          ]}
        >
          <View styles={{ flexGrow: 1, marginBottom: "20px" }}>
            <PDFHeader
              styles={styles}
              theme={theme}
              logo={themeContext.email_logo}
              title={product?.product_name}
            />
            <View style={styles.WRAP}>
              <View style={[styles.TABLE.ROW, { border: "none " }]}>
                {/* <Text style={[styles.TEXT, styles.HEADERTEXT]} >Product Details</Text> */}
                <View style={[styles.TABLE.COL, { border: "none " }]}>
                  {mainImage ? (
                    <Image
                      src={mainImage}
                      style={{
                        width: "200px",
                        height: "150px",
                        objectFit: "contain",
                        margin: "0 auto 20px",
                      }}
                    />
                  ) : null}
                  {techImage ? (
                    <Image
                      src={techImage}
                      style={{
                        maxWidth: "250px",
                        maxHeight: "150px",
                        objectFit: "contain",
                      }}
                    />
                  ) : null}

                  {brandImage && !mainImage && !techImage ? (
                    <View style={[styles.TABLE.CELL, { marginBottom: 10 }]}>
                      <Image
                        src={brandImage}
                        style={{
                          padding: "24px",
                          width: "100%",
                          height: "250px",
                          objectFit: "contain",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </View>
                  ) : !mainImage && !techImage ? (
                    <View
                      style={{
                        width: "100%",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={[
                          styles.TEXT,
                          { fontSize: "16px", color: "#333" },
                        ]}
                      >
                        No image available
                      </Text>
                    </View>
                  ) : null}
                </View>

                <View style={[styles.TABLE.COL, { border: "none " }]}>
                  {brandImage ? (
                    <View style={[styles.TABLE.CELL, { marginBottom: 10 }]}>
                      <Image
                        src={brandImage}
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </View>
                  ) : null}
                  {/* <View style={styles.TABLE.CELL} >
                                <Text style={styles.TEXT}>
                                    <Text style={[styles.TEXT, styles.BOLDTEXT]}>Name: </Text>
                                    {product?.product_name}
                                </Text>
                            </View> */}
                  <View style={styles.TABLE.CELL}>
                    <Text style={styles.TEXT}>
                      <Text style={[styles.TEXT, styles.BOLDTEXT]}>
                        {config.APPNAME === "act"
                          ? "ACT No"
                          : config.APPNAME === "cis"
                          ? "CIS No"
                          : "ERP No"}
                        :{" "}
                      </Text>
                      <Link
                        src={`${baseUrl}/search/search/product/${product?.product_id}?tab=1`}
                        style={{ color: "#333" }}
                      >
                        {product?.product_id}
                      </Link>
                    </Text>
                  </View>
                  <View style={styles.TABLE.CELL}>
                    <Text style={styles.TEXT}>
                      <Text style={[styles.TEXT, styles.BOLDTEXT]}>
                        Manufacturer No (J21):{" "}
                      </Text>
                      {product?.product_supplier_number}
                    </Text>
                  </View>
                  {product?.product_ean ? (
                    <View style={styles.TABLE.CELL}>
                      <Text style={styles.TEXT}>
                        <Text style={[styles.TEXT, styles.BOLDTEXT]}>
                          EAN:{" "}
                        </Text>
                        {product?.product_ean}
                      </Text>
                    </View>
                  ) : null}
                  {product?.product_description ? (
                    <View style={styles.TABLE.CELL}>
                      <Text style={styles.TEXT}>
                        <Text style={[styles.TEXT, styles.BOLDTEXT]}>
                          Description:{" "}
                        </Text>
                        {product?.product_description}
                      </Text>
                    </View>
                  ) : null}
                  {product?.product_packet_size ? (
                    <View style={styles.TABLE.CELL}>
                      <Text style={styles.TEXT}>
                        <Text style={[styles.TEXT, styles.BOLDTEXT]}>
                          Pack size:{" "}
                        </Text>
                        {product?.product_packet_size}
                      </Text>
                    </View>
                  ) : null}

                  {price ? (
                    <>
                      {product?.price_incl_discount ? (
                        <View
                          style={[
                            styles.TABLE.CELL,
                            {
                              border: "1px solid rgb(105, 173, 108)",
                              borderRadius: "5px",
                              backgroundColor: "#e4ffe4",
                              margin: "10px 10px 0 10px",
                              padding: "10px 10px 5px 10px",
                            },
                          ]}
                        >
                          <View
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <View style={{ width: 30 }}>
                              <PDFFontAwesome
                                faIcon={
                                  product?.price_incl_discount !==
                                  product?.price_rrp
                                    ? faBadgePercent
                                    : faTags
                                }
                                style={{
                                  color: "rgb(105, 173, 108)",
                                  width: 16,
                                  height: 16,
                                }}
                              />
                            </View>
                            <View>
                              <Text
                                style={[
                                  styles.TEXT,
                                  {
                                    fontSize: "12px",
                                    color: "rgb(105, 173, 108)",
                                  },
                                ]}
                              >
                                {product?.price_incl_discount !==
                                  product?.price_rrp && product?.price_rrp ? (
                                  <Text
                                    style={[
                                      styles.TEXT,
                                      styles.BOLDTEXT,
                                      {
                                        marginRight: "10px",
                                        color: "#999",
                                        position: "relative",
                                        fontSize: "12px",
                                        textDecoration: "line-through",
                                      },
                                    ]}
                                  >
                                    £{product.price_rrp.toFixed(2)}{" "}
                                  </Text>
                                ) : null}
                                <Text
                                  style={[
                                    styles.TEXT,
                                    styles.BOLDTEXT,
                                    { fontSize: "12px" },
                                  ]}
                                >
                                  {" "}
                                  £{product?.price_incl_discount.toFixed(2)}
                                </Text>
                                <Text
                                  style={[
                                    styles.TEXT,
                                    { fontSize: "12px", color: "#333" },
                                  ]}
                                >
                                  {" "}
                                  each
                                </Text>
                              </Text>
                            </View>
                          </View>
                        </View>
                      ) : null}
                    </>
                  ) : null}

                  <View
                    style={[
                      styles.TABLE.CELL,
                      {
                        border: "1px solid #0288d1",
                        borderRadius: "5px",
                        backgroundColor: "#e8f6fb",
                        margin: "5px 10px 10px 10px",
                        padding: "10px 10px 5px 10px",
                      },
                    ]}
                  >
                    {product?.product_stock ? (
                      <View
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 5,
                        }}
                      >
                        <View style={{ width: 30 }}>
                          <PDFFontAwesome
                            faIcon={faDolly}
                            style={{ color: "#0288d1", width: 16, height: 16 }}
                          />
                        </View>
                        <View>
                          <Text style={[styles.TEXT, { fontSize: "10px" }]}>
                            <Text
                              style={[
                                styles.TEXT,
                                styles.BOLDTEXT,
                                { fontSize: "10px", color: "#333" },
                              ]}
                            >
                              {config.APPNAME === "act"
                                ? "ACT"
                                : config.APPNAME === "cis"
                                ? "CIS"
                                : "ERP"}{" "}
                              Stock:{" "}
                            </Text>
                            {product.product_stock} available
                          </Text>
                          <Text style={[styles.TEXT, { fontSize: "10px" }]}>
                            <Text
                              style={[
                                styles.TEXT,
                                styles.BOLDTEXT,
                                { fontSize: "10px", color: "#333" },
                              ]}
                            >
                              Estimated Delivery:{" "}
                            </Text>
                            {product.product_local_delivery}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    <View
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <View style={{ width: 30 }}>
                        <PDFFontAwesome
                          faIcon={faTruck}
                          style={{ color: "#0288d1", width: 16, height: 16 }}
                        />
                      </View>
                      <View>
                        <Text style={[styles.TEXT, { fontSize: "10px" }]}>
                          <Text
                            style={[
                              styles.TEXT,
                              styles.BOLDTEXT,
                              { fontSize: "10px", color: "#333" },
                            ]}
                          >
                            Manufacturer Stock:{" "}
                          </Text>
                          Available
                        </Text>
                        {product?.product_external_delivery ? (
                          <Text style={[styles.TEXT, { fontSize: "10px" }]}>
                            <Text
                              style={[
                                styles.TEXT,
                                styles.BOLDTEXT,
                                { fontSize: "10px", color: "#333" },
                              ]}
                            >
                              Estimated Delivery:{" "}
                            </Text>
                            {product?.product_external_delivery}
                          </Text>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {product?.product_attributes ? (
              <View style={[{ padding: "0 16px 16px 16px" }]}>
                <Text
                  fixed
                  style={[
                    styles.TEXT,
                    styles.HEADERTEXT,
                    { margin: "10px 0 0 0", padding: "16px 0 10px 0" },
                  ]}
                >
                  Product Features
                </Text>
                <View style={styles.TABLE}>
                  {create_pdf_rows({
                    rows: product?.product_attributes,
                    styles,
                  })}
                </View>
              </View>
            ) : null}
            {otherImages && otherImages.length > 0 ? (
              <View style={[styles.WRAP, { backgroundColor: "#FFF" }]}>
                <Text
                  style={[
                    styles.TEXT,
                    styles.HEADERTEXT,
                    { marginBottom: "10px" },
                  ]}
                >
                  More Images
                </Text>
                {otherImages && otherImages.length > 0
                  ? create_images({
                      images: otherImages,
                      styles,
                    })
                  : null}
              </View>
            ) : null}

            {product?.product_speeds ? (
              <View style={[{ padding: "0 16px 16px 16px" }]}>
                <Text
                  fixed
                  style={[styles.TEXT, styles.HEADERTEXT, { margin: "10px 0" }]}
                >
                  Speeds & Feeds
                </Text>
                <View style={[{ padding: "0 16px 16px 16px" }]}>
                  {create_speeds_feeds({
                    speeds_feeds: product?.product_speeds,
                    styles,
                  })}
                </View>
              </View>
            ) : null}

            {product?.product_downloads ? (
              <View style={[{ padding: "0 16px 16px 16px" }]}>
                <Text
                  fixed
                  style={[styles.TEXT, styles.HEADERTEXT, { margin: "10px 0" }]}
                >
                  Available Downloads
                </Text>
                <View style={[{ padding: "0 16px 16px 16px" }]}>
                  {create_product_downloads({
                    downloads: product?.product_downloads,
                    styles,
                  })}
                </View>
              </View>
            ) : null}
          </View>
          <View fixed>
            <PDFFooter styles={styles} theme={theme} />
          </View>
        </Page>
      </Document>
    ),
    [brandImage, baseUrl, price]
  );

  const [instance, updateInstance] = usePDF({ document });

  useEffect(() => {
    if (document) {
      updateInstance(document);
    }
  }, [document]);

  useEffect(() => {
    if (instance.blob) {
      // console.log(instance)
      setDownloadObject(instance);
    }
  }, [instance, setDownloadObject]);

  return (
    <>
      {instance.url ? (
        <object
          data={instance.url}
          type="application/pdf"
          height="100%"
          width="100%"
        >
          {/* <ReactPDFFallback url={instance.url} /> */}
        </object>
      ) : null}
    </>
  );
};

export default SingleProductPDF;
